import { Alert, Box, Button, Checkbox, Dialog, DialogContentText, FormControlLabel, FormGroup, IconButton, Snackbar, Stack, Switch, Tab, ThemeProvider, Tooltip, Typography, TextField, InputAdornment } from "@mui/material";
import { theme } from "../Theme";
import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import { allocateToProcessor, allocateToTeam, getPresentAllocs, removeWorkstationpamulti, setWorkstationpamulti, removeEmailWorkstationpamulti, setEmailWorkstationpamulti, workingpa, workstationpa, emailworkstationpa, workstatuspa, workstatuspalead, getPresentLeadAllocs } from "../api";
import { Create, DoneOutline, FiberManualRecord, ShortcutOutlined, Stop, UndoOutlined, Work, WorkOutline } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { useUser } from "../UserProvider";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Sort as SortIcon } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { getName, getUserName, isLeadManager, isLeadEmailAgent } from "../msalService";
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export default function Archive() {
  const [domains, setDomains] = useState([]);

  let [val, setVal] = useState("");
  let [b, setB] = useState(false);

  const [portfolio, setPortfolio] = useState('ALL');
  let [psize, setPsize] = useState(50);
  const [idds, setIdds] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [upsuc2, setUpsuc2] = useState(false);
  const [upfail2, setUpfail2] = useState(false);
  const [processor, setProcessor] = useState([]);
  const [team, setTeam] = useState([]);

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [checked, setChecked] = useState(false);

  const { user } = useUser();

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  const handleProcessorChange = (event) => {
    setProcessor(event.target.value);
  };

  const handleTeamChange = (event) => {
    setTeam(event.target.value);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
  };

  const columns = useMemo(() => [
    {
      id: 'leadName',
      accessorKey: 'leadName',
      header: 'Lead Name',
      size: 200
    },
    {
      id: 'domainName',
      header: 'Domain Name',
      accessorFn: (row) => row.presentLead?.domainName ?? 'N/A',
      size: 200
    },
    {
      id: 'dateOfAlloc',
      accessorKey: 'dateOfAlloc',
      header: 'alloc date',
      size: 100,
      accessorFn: (row) => new Date(row.dateOfAlloc),
      Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
    },
    {
      id: 'classification',
      header: 'Classification',
      accessorFn: (row) => row.presentLead?.classification || 'N/A',
      size: 120
    },
    {
      id: 'imp',
      header: 'Important',
      accessorFn: (row) => row.presentLead?.imp ?? false,
      Cell: ({ cell }) => (
        <Box sx={{
          display: 'flex',
          // justifyContent: 'center',
          color: cell.getValue() ? '#4CAF50' : '#8797A8',
          fontSize: '0.7rem',
          fontWeight: 'bold',
          fontStyle: 'italic'
        }}>
          {cell.getValue() ? 'Yes' : 'No'}
        </Box>
      ),
      size: 80,
    },
    {
      id: 'allocator',
      header: 'Allocator',
      size: 150,
      accessorFn: (row) => row.allocator ? `${row.allocator.firstName} ${row.allocator.lastName}` : 'N/A',
    }
  ], [domains, setDomains]);

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {
      status: {
        operator: "equals",
        value: 2,
        type: "number"
      }
    });

    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    getPresentLeadAllocs({
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
    })
      .then(response => {
        if (response && response.data) {
          setDomains(response.data.content);
          setTotalRowCount(response.data.totalElements);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setDomains([]);
        setTotalRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [portfolio, pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData])

  // Define common button style with updated disabled state
  const commonButtonStyle = {
    textTransform: 'none',
    backgroundColor: '#8797A8',
    color: '#FFFFFF !important',
    height: '34px',
    padding: '4px 8px',
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '&:hover': {
      backgroundColor: '#8797A8',
      opacity: 0.9,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#8797A8',
      opacity: 0.7,
      color: '#FFFFFF !important',
    }
  };

  const searchFieldStyles = {
    width: '160px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
      fontSize: '0.875rem',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderRadius: '12px',
      },
      '&:hover fieldset': {
        borderColor: '#8797A8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8797A8',
      },
    },
  };

  const domainTable = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,
    enableExpanding: true,
    renderDetailPanel: ({ row }) => {
      const topContacts = row.original.leadWork?.topContacts || [];
      
      if (topContacts.length === 0) {
        return (
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" fontStyle="italic">No contacts available</Typography>
          </Box>
        );
      }

      const contactColumns = [
        {
          header: 'Name',
          accessorKey: 'name',
          size: 150,
        },
        {
          header: 'Primary Email',
          accessorKey: 'primaryEmail',
          size: 200,
        },
        {
          header: 'Designation',
          accessorKey: 'designation',
          size: 150,
        },
      ];

      return (
        <Box sx={{ p: 2 }}>
          <MaterialReactTable
            columns={contactColumns}
            data={topContacts}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            muiTableHeadCellProps={{
              sx: {
                backgroundColor: '#f5f5f5',
                fontWeight: 'bold',
                fontSize: '0.7rem',
                padding: '8px',
              },
            }}
            muiTableBodyCellProps={{
              sx: {
                fontSize: '0.7rem',
                padding: '8px',
              },
            }}
          />
        </Box>
      );
    },
    muiExpandButtonProps: ({ row }) => ({
      sx: {
        color: row.original.leadWork?.topContacts?.length > 0 ? '#8797A8' : '#ccc',
      },
    }),
    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0'
      }
    },
    muiTableContainerProps: {
      sx: { maxHeight: '600px' }
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      }
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        <Button
          variant="outlined"
          size="small"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={commonButtonStyle}
        >
          Filter
        </Button>

        <Button
          variant="outlined"
          size="small"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={commonButtonStyle}
        >
          Sort
        </Button>

        {isLeadEmailAgent &&
          <Button
            disabled={table.getSelectedRowModel().rows.length === 0}
            variant="outlined"
            startIcon={<FileDownloadIcon />}
            onClick={handleExport}
            sx={commonButtonStyle}
          >
            Export
          </Button>}

        <Box flexGrow={1} />

        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={searchFieldStyles}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={{
                    fontSize: '1rem',
                    color: '#8797A8',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#6b7a8a',
                    },
                  }}
                  onClick={handleSearch}
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),
    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading
    },
  });

  const getCurrentDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = String(now.getFullYear());
    return `lead-contacts-${day}-${month}-${year}.csv`;
  };

  const handleExport = useCallback(() => {
    const rows = domainTable.getPrePaginationRowModel().rows;

    const exportData = rows.map(row => {
      const originalData = row.original;
      const contacts = originalData.leadWork?.topContacts || [];
      
      const baseRecord = {
        'Domain Name': originalData.presentLead?.domainName || originalData.leadName || '',
        'Lead Name': originalData.leadName || '',
        'Allocation Date': new Date(originalData.dateOfAlloc).toISOString().split('T')[0],
        'Classification': originalData.presentLead?.classification || '',
        'Important': originalData.presentLead?.imp ? 'Yes' : 'No',
        'Allocator': `${originalData.allocator?.firstName || ''} ${originalData.allocator?.lastName || ''}`.trim(),
      };

      if (contacts.length === 0) {
        return [{ ...baseRecord, 'Contact Name': '', 'Primary Email': '', 'Designation': '' }];
      }

      return contacts.map(contact => ({
        ...baseRecord,
        'Contact Name': contact.name || '',
        'Primary Email': contact.primaryEmail || '',
        'Designation': contact.designation || ''
      }));
    }).flat();

    const headers = [
      'Domain Name',
      'Lead Name',
      'Allocation Date',
      'Classification',
      'Important',
      'Allocator',
      'Contact Name',
      'Primary Email',
      'Designation'
    ];

    const csvContent = [
      headers.join(','),
      ...exportData.map(row =>
        headers.map(header =>
          `"${(row[header] || '').toString().replace(/"/g, '""')}"`
        ).join(',')
      )
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', getCurrentDateTime());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [domainTable]);

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Lead Archive
          </Typography>
        </Stack>
        <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Moved to W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Moved to W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upsuc2} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc2(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Removed from W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail2} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail2(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Removed from W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Allotted!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Allotted!
          </Alert>
        </Snackbar>
        <Box>
          <MaterialReactTable table={domainTable} />
        </Box>
        <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
          <DialogTitle>
            <Stack direction='row'>
              <Typography variant="h6">Allocate to </Typography>
              <FormControlLabel
                sx={{ p: 0, m: 0, ml: 1 }}
                control={
                  <Switch
                    checked={checked}
                    onChange={(event) => { setChecked(event.target.checked); }}
                    size='small'
                  />
                }
                label={
                  <Typography sx={{ p: 0, m: 0 }} variant="h6">
                    {checked ? 'Processor' : "Team"}
                  </Typography>
                }
                labelPlacement="start"
              />
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack direction='row' width='100%' justifyContent='space-between'><DialogContentText>
              {idds.length + " " + portfolio} domain{idds.length != 1 && 's'} selected
            </DialogContentText></Stack>
            <FormGroup sx={{ mt: 2 }}>
              <Stack direction='row' spacing={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="processor-label">Select Processor</InputLabel>
                  <Select
                    disabled={!checked}
                    labelId="processor-label"
                    id="processor-select"
                    value={processor}
                    label="Select Processor"
                    onChange={handleProcessorChange}
                  >
                    {user.users.map((child) => (
                      <MenuItem key={child.email} value={child.email}>{child.firstName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="team-label">Select Team</InputLabel>
                  <Select
                    disabled={checked}
                    labelId="team-label"
                    id="team-select"
                    value={team}
                    label="Select Team"
                    onChange={handleTeamChange}
                  >
                    {user.teams.map((child) => (
                      <MenuItem key={child.email} value={child.email}>Team {child.firstName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAllocate}>Cancel</Button>
            <Button variant="contained" onClick={() => {
              if (checked) {
                allocateToProcessor(idds, processor).then(() => { setUpsuc1(true) }).catch(() => { setUpfail1(true) })
              }
              else allocateToTeam(idds, processor).then(() => { setUpsuc1(true) }).catch(() => { setUpfail1(true) })
              handleCloseAllocate();
            }}>Allocate</Button>
          </DialogActions>
        </Dialog>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />
        </LocalizationProvider>
      </Stack>
    </ThemeProvider>
  );
}