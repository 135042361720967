import React from 'react';
import { Button } from '@mui/material';

const ExportPendingLeads = ({ syncData }) => {
  const headers = [
    { label: "Domain", key: "domain" },
    { label: "Status", key: "status" },
    { label: "Type", key: "type" },
    { label: "EST", key: "est" },
    { label: "PP", key: "pp" },
    { label: "Deadline", key: "deadline" },
    { label: "Order Date", key: "orderDate" },
    { label: "Site", key: "site" },
    { label: "Important", key: "imp" },
    { label: "Contact Form", key: "contactForm" },
    { label: "Name", key: "name" },
    { label: "Official Email", key: "officialEmail" },
    { label: "Personal Email", key: "personalEmail" },
    { label: "Level", key: "level" },
    { label: "Classification", key: "classification" },
    { label: "Source", key: "source" },
    { label: "Allied", key: "allied" },
    { label: "Allied Remark", key: "alliedRemark" },
    { label: "Recycle", key: "recycle" }
  ];

  const transformData = () => {
    const exportData = [];
    
    syncData.list.forEach(item => {
      const domain = item.domain;
      
      // Only process if there are pending leads
      if (item.pendingLeads && item.pendingLeads.length > 0) {
        item.pendingLeads.forEach(lead => {
          if (lead.contacts && lead.contacts.length > 0) {
            // If lead has contacts, create a row for each contact
            lead.contacts.forEach(contact => {
              exportData.push({
                domain: domain.domain,
                status: domain.status,
                type: domain.portfolio,
                est: domain.EST,
                pp: domain.PP,
                deadline: domain.deadline || 'N/A',
                orderDate: domain.orderDate || 'N/A',
                site: lead.site,
                classification: lead.classification || '',
                source: lead.source || '',
                allied: lead.allied ? 'Yes' : 'No',
                alliedRemark: lead.alliedRemark || '',
                recycle: lead.recycle ? 'Yes' : 'No',
                imp: lead.imp ? 'Yes' : 'No',
                contactForm: lead.contactForm ? 'Yes' : 'No',
                name: contact.name || '',
                officialEmail: contact.officialEmail || '',
                personalEmail: contact.personalEmail || '',
                level: contact.designation || '' // Using designation field as level
              });
            });
          } else {
            // If lead has no contacts, create a row with just lead info
            exportData.push({
              domain: domain.domain,
              status: domain.status,
              type: domain.portfolio,
              est: domain.EST,
              pp: domain.PP,
              deadline: domain.deadline || 'N/A',
              orderDate: domain.orderDate || 'N/A',
              site: lead.site,
              classification: lead.classification || '',
              source: lead.source || '',
              allied: lead.allied ? 'Yes' : 'No',
              alliedRemark: lead.alliedRemark || '',
              recycle: lead.recycle ? 'Yes' : 'No',
              imp: lead.imp ? 'Yes' : 'No',
              contactForm: lead.contactForm ? 'Yes' : 'No',
              name: '',
              officialEmail: '',
              personalEmail: '',
              level: ''
            });
          }
        });
      }
    });
    
    return exportData;
  };

  const handleExport = () => {
    const data = transformData();
    
    // Convert to CSV
    const csvContent = [
      headers.map(h => h.label).join(','),
      ...data.map(row => 
        headers.map(header => {
          const value = row[header.key];
          // Handle values that might contain commas
          if (typeof value === 'string' && value.includes(',')) {
            return `"${value}"`;
          }
          return value === null || value === undefined ? '' : value;
        }).join(',')
      )
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `pending_leads_${new Date().toISOString().split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <Button
      variant="text"
      color="primary"
      onClick={handleExport}
      style={{ marginLeft: '10px' }}
    >
      Export P-Leads
    </Button>
  );
};

export default ExportPendingLeads; 