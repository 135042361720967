import { Alert, Box, Button, Stack, ThemeProvider, Typography, TextField, Checkbox, FormControlLabel, Snackbar, IconButton, Tooltip } from "@mui/material";
import { theme } from "../Theme";
import React, { useState, useEffect, useMemo, memo } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { getAllTopLeadEmailAllocs, setEmailAllocationsWork } from "../api";
import { Add, Remove, Upload, AddCircle, RemoveCircle } from "@mui/icons-material";
import { openDB } from 'idb';

// Initialize IndexedDB
const initDB = async () => {
  return openDB('emailWorkStationDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('allocations')) {
        db.createObjectStore('allocations', { keyPath: 'id', autoIncrement: true });
      }
    },
  });
};

// Save data to IndexedDB
const saveToDB = async (data) => {
  const db = await initDB();
  const tx = db.transaction('allocations', 'readwrite');
  const store = tx.objectStore('allocations');
  await store.clear();
  await Promise.all(data.map(item => store.put(item)));
  await tx.done;
};

// Load data from IndexedDB
const loadFromDB = async () => {
  const db = await initDB();
  const tx = db.transaction('allocations', 'readonly');
  const store = tx.objectStore('allocations');
  const allData = await store.getAll();
  await tx.done;
  return allData;
};

const createEmailRow = () => ({
  id: null,
  email: null,
  name: null,
  designation: null,
  linkedinUrl: null
});

// Add this new component for the row actions
const RowActionsE = memo(({ index, totalEmails, handleAddEmail, handleRemoveEmail, isNoEmails }) => {
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      <Tooltip title={isNoEmails ? "No emails available" : "Add"}>
        <span>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            color="primary"
            onClick={() => handleAddEmail()}
            disabled={isNoEmails}
          >
            <AddCircle fontSize="small" sx={{ padding: 0 }} />
          </IconButton>
        </span>
      </Tooltip>
      {(!(index === 0 && totalEmails === 1)) && (
        <Tooltip title={isNoEmails ? "No emails available" : "Remove"}>
          <span>
            <IconButton
              size="small"
              sx={{ padding: 0 }}
              color="primary"
              onClick={() => handleRemoveEmail(index)}
              disabled={isNoEmails}
            >
              <RemoveCircle fontSize="small" sx={{ padding: 0 }} />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  );
});

// Update the EmailSection component
const EmailSection = memo(({ row: parentRow, updateDomains }) => {
  const emailsData = parentRow.original.emailWork?.emails || [createEmailRow()];
  const isNoEmails = parentRow.original.emailWork?.noEmailsAvailable || false;

  const handleEmailFieldChange = (index, field, value) => {
    updateDomains(prevDomains => {
      const newDomains = [...prevDomains];
      const domainIndex = newDomains.findIndex(d => d.id === parentRow.original.id);
      if (domainIndex !== -1) {
        const currentEmails = newDomains[domainIndex].emailWork?.emails || [createEmailRow()];
        newDomains[domainIndex] = {
          ...newDomains[domainIndex],
          emailWork: {
            ...newDomains[domainIndex].emailWork,
            emails: currentEmails.map((email, i) => 
              i === index ? { ...email, [field]: value } : email
            )
          }
        };
      }
      saveToDB(newDomains);
      return newDomains;
    });
  };

  const handleAddEmail = () => {
    updateDomains(prevDomains => {
      const newDomains = [...prevDomains];
      const domainIndex = newDomains.findIndex(d => d.id === parentRow.original.id);
      if (domainIndex !== -1) {
        newDomains[domainIndex] = {
          ...newDomains[domainIndex],
          emailWork: {
            ...newDomains[domainIndex].emailWork,
            emails: [...newDomains[domainIndex].emailWork.emails, createEmailRow()]
          }
        };
      }
      saveToDB(newDomains);
      return newDomains;
    });
  };

  const handleRemoveEmail = (index) => {
    updateDomains(prevDomains => {
      const newDomains = [...prevDomains];
      const domainIndex = newDomains.findIndex(d => d.id === parentRow.original.id);
      if (domainIndex !== -1) {
        newDomains[domainIndex] = {
          ...newDomains[domainIndex],
          emailWork: {
            ...newDomains[domainIndex].emailWork,
            emails: newDomains[domainIndex].emailWork.emails.filter((_, i) => i !== index)
          }
        };
      }
      saveToDB(newDomains);
      return newDomains;
    });
  };

  return (
    <Box sx={{ width: '100%', opacity: isNoEmails ? 0.5 : 1 }}>
      <Stack spacing={2}>
        {emailsData.map((email, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={2}
            alignItems="center"
          >
            <EmailField
              placeholder="Email"
              value={email.email}
              onChange={(value) => handleEmailFieldChange(index, 'email', value)}
            />
            <EmailField
              placeholder="Name"
              value={email.name}
              onChange={(value) => handleEmailFieldChange(index, 'name', value)}
            />
            <EmailField
              placeholder="Designation"
              value={email.designation}
              onChange={(value) => handleEmailFieldChange(index, 'designation', value)}
              isSelect={true}
            />
            <EmailField
              placeholder="LinkedIn URL"
              value={email.linkedinUrl}
              onChange={(value) => handleEmailFieldChange(index, 'linkedinUrl', value)}
            />
            <RowActionsE 
              index={index}
              totalEmails={emailsData.length}
              handleAddEmail={handleAddEmail}
              handleRemoveEmail={handleRemoveEmail}
              isNoEmails={isNoEmails}
            />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
});

const EmailField = memo(({ placeholder, value, onChange, isSelect }) => {
  if (isSelect) {
    return (
      <TextField
        select
        placeholder={placeholder}
        value={value || ''}
        onChange={(e) => onChange(e.target.value)}
        size="small"
        variant="outlined"
        SelectProps={{
          native: true,
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            height: '25px',
            fontSize: '12px',
          },
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          padding: 0,
          margin: 0,
          minWidth: '100px',
        }}
      >
        <option value="">Designation</option>
        <option value="CXO">CXO</option>
        <option value="VP/Dir">VP/Dir</option>
        <option value="Legal">Legal</option>
        <option value="Other">Other</option>
      </TextField>
    );
  }

  return (
    <TextField
      placeholder={placeholder}
      value={value || ''}
      onChange={(e) => onChange(e.target.value || '')}
      size="small"
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '25px',
          fontSize: '12px',
        },
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0,
      }}
    />
  );
});

export default function EmailHub() {
  const [domains, setDomains] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [openF, setOpenF] = useState(false);
  const [num, setNum] = useState(0);
  const [den, setDen] = useState(0);

  useEffect(() => {
    updateDataFromBackend();
  }, []);

  // Update data from backend and IndexedDB
  const updateDataFromBackend = async () => {
    try {
      setLoading(true);
      const freshData = await getAllTopLeadEmailAllocs();
      const freshAllocations = freshData.data.map(allocation => ({
        ...allocation,
        emailWork: {
          ...allocation.emailWork,
          emails: allocation.emailWork?.emails?.length > 0 
            ? allocation.emailWork.emails 
            : [createEmailRow()]
        }
      }));

      // Load current allocations from IndexedDB
      const currentAllocs = await loadFromDB();

      // Identify entries to remove and add
      const currentIds = new Set(currentAllocs.map(alloc => alloc.id));
      const freshIds = new Set(freshAllocations.map(alloc => alloc.id));

      const toRemove = currentAllocs.filter(alloc => !freshIds.has(alloc.id));
      const toAdd = freshAllocations.filter(alloc => !currentIds.has(alloc.id));

      // Combine current allocations and new additions
      const updatedAllocs = [
        ...currentAllocs.filter(alloc => freshIds.has(alloc.id)),
        ...toAdd
      ];

      // Update state with the combined allocations
      setDomains(updatedAllocs);

      // Update IndexedDB asynchronously
      const db = await initDB();
      const tx = db.transaction('allocations', 'readwrite');
      const store = tx.objectStore('allocations');

      // Remove entries not in freshAllocations
      await Promise.all(toRemove.map(alloc => store.delete(alloc.id)));

      // Add new entries from freshAllocations
      await Promise.all(toAdd.map(alloc => store.put(alloc)));

      await tx.done;
    } catch (error) {
      console.error("Error fetching email allocations:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const selectedRows = table.getSelectedRowModel().rows.map(row => row.original);
    setDen(selectedRows.length);
    try {
      const res = await setEmailAllocationsWork(selectedRows);
      setNum(res.data.successCount);
      if (res.data.successCount > 0) {
        setOpenS(true);
        setDomains(prevDomains => {
          const remainingDomains = prevDomains.filter(domain => !selectedRows.includes(domain));
          saveToDB(remainingDomains);
          return remainingDomains;
        });
        updateDataFromBackend();
      } else {
        setOpenF(true);
      }
    } catch (err) {
      console.error("Error submitting data:", err);
      setOpenF(true);
    }
  };

  const columns = useMemo(() => [
    {
      id: 'leadName',
      header: 'Lead Name',
      accessorKey: 'leadName',
      size: 150,
      Cell: ({ row }) => (
        <Typography sx={{ fontSize: '0.8rem' }}>
          {row.original.leadName || 'N/A'}
        </Typography>
      ),
    },
    {
      id: 'noEmails',
      header: 'No Emails',
      size: 80,
      Cell: ({ row }) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={row.original.emailWork?.noEmailsAvailable || false}
              onChange={(event) => {
                const newValue = event.target.checked;
                setDomains(prevDomains => {
                  const newDomains = [...prevDomains];
                  const domainIndex = newDomains.findIndex(d => d.id === row.original.id);
                  if (domainIndex !== -1) {
                    newDomains[domainIndex] = {
                      ...newDomains[domainIndex],
                      emailWork: {
                        ...newDomains[domainIndex].emailWork,
                        noEmailsAvailable: newValue
                      }
                    };
                  }
                  saveToDB(newDomains);
                  return newDomains;
                });
              }}
              size="small"
              sx={{
                padding: '2px',
                '& .MuiSvgIcon-root': { fontSize: 16 }
              }}
            />
          }
          label=""
          sx={{ margin: 0, height: 25 }}
        />
      ),
    },
    {
      id: 'emails',
      header: 'Email Details',
      size: 800,
      Cell: ({ row }) => (
        <EmailSection 
          row={row}
          updateDomains={setDomains}
        />
      ),
    }
  ], [setDomains]);

  const table = useMaterialReactTable({
    columns,
    data: domains,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    state: {
      isLoading: loading,
    },
    initialState: {
      density: 'compact',
    },
    muiTableBodyProps: { 
      sx: { 
        backgroundColor: 'whitesmoke',
      } 
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: { 
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      } 
    }),
    muiTablePaperProps: { 
      elevation: 0,
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      } 
    },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: '#FFC0CB',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: '0.8rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.8rem',
        padding: '1px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction='row' width='100%' alignItems='center'>
        <Button
          variant="text"
          startIcon={<Upload />}
          disabled={table.getSelectedRowModel().rows.length === 0}
          onClick={handleSubmit}
          sx={{
            textTransform: 'none',
            backgroundColor: '#8797A8',
            color: '#FFFFFF !important',
            padding: '4px 8px',
            minWidth: 'auto',
            marginRight: '8px',
            '& .MuiButton-startIcon': {
              marginRight: '4px',
            },
            '&:hover': {
              backgroundColor: '#8797A8',
              opacity: 0.9,
              boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            },
            '&.Mui-disabled': {
              backgroundColor: '#8797A8',
              opacity: 0.7,
            }
          }}
        >
          Submit
        </Button>
        {table.getSelectedRowModel().rows.length > 0 && (
          <Typography variant='body2' fontWeight='bold'>
            {table.getSelectedRowModel().rows.length} of {domains.length} domains selected
          </Typography>
        )}
      </Stack>
    ),
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack spacing={2}>
        <Stack direction='row' width='100%' justifyContent='space-between'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}>
            Email Hub
          </Typography>
        </Stack>
        <Snackbar 
          open={openS} 
          autoHideDuration={2000} 
          onClose={() => setOpenS(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="success">
            {num} out of {den} submitted!
          </Alert>
        </Snackbar>
        <Snackbar 
          open={openF} 
          autoHideDuration={2000} 
          onClose={() => setOpenF(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error">
            Not Submitted!
          </Alert>
        </Snackbar>
        <Box>
          <MaterialReactTable table={table} />
        </Box>
      </Stack>
    </ThemeProvider>
  );
}
