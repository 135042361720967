import { Alert, Box, Button, Dialog, DialogContentText, FormControlLabel, FormGroup, Snackbar, Stack, Switch, Tab, ThemeProvider, Typography, TextField, InputAdornment, Autocomplete } from "@mui/material";
import { theme } from "../Theme";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Add, FiberManualRecord, PanToolAltOutlined } from "@mui/icons-material";
import { useUser } from "../UserProvider";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Sort as SortIcon } from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import LeadAllocationDialog from '../Common/LeadAllocationDialog';
import { getLeadAgentsChildren, getNonAllocLeads } from "../api";

export default function NonAllocatedLeads() {
  // State management
  const [idds, setIdds] = useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);
  const [domains, setDomains] = useState([]);
  const [leadAgentsChildren, setLeadAgentsChildren] = useState([]);


  // Table state
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');

  const { user } = useUser();

  // Handlers
  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
  };

  const columns = useMemo(() => [
    {
      id: 'site',
      accessorKey: 'site',
      header: 'Lead Name',
      size: 150
    },
    {
      id: 'domainName',
      accessorKey: 'domainName',
      header: 'Domain Name',
      size: 150
    },
    {
      id: 'classification',
      accessorKey: 'classification',
      header: 'Classification',
      size: 100
    },
    {
      id: 'source',
      accessorKey: 'source',
      header: 'Source',
      size: 100
    },
    {
      id: 'imp',
      accessorKey: 'imp',
      header: 'Important',
      size: 80,
      Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No'
    },
    {
      id: 'recycle',
      accessorKey: 'recycle',
      header: 'Recycle',
      size: 80,
      Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No'
    },
    {
      id: 'allied',
      accessorKey: 'allied',
      header: 'Allied',
      size: 80,
      Cell: ({ cell }) => cell.getValue() ? 'Yes' : 'No'
    },
    {
      id: 'alliedRemark',
      accessorKey: 'alliedRemark',
      header: 'Allied Remark',
      size: 150
    },
    {
      id: 'leadAllocationState',
      accessorKey: 'leadAllocationState',
      header: 'Allocation State',
      size: 120
    }
  ], []);

  // Styles
  const commonButtonStyle = {
    textTransform: 'none',
    backgroundColor: '#8797A8',
    color: '#FFFFFF !important',
    height: '34px',
    padding: '4px 8px',
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '&:hover': {
      backgroundColor: '#8797A8',
      opacity: 0.9,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#8797A8',
      opacity: 0.7,
      color: '#FFFFFF !important',
    }
  };

  const searchFieldStyles = {
    width: '160px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
      fontSize: '0.875rem',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderRadius: '12px',
      },
      '&:hover fieldset': {
        borderColor: '#8797A8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8797A8',
      },
    },
  };

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    getNonAllocLeads({
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
    })
      .then(response => {
        if (response && response.data) {
          setDomains(response.data.content);
          setTotalRowCount(response.totalElements);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setDomains([]);
        setTotalRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getLeadAgentsChildren().then((response) => {
      setLeadAgentsChildren(response.data);
    });
  }, []);

  // Table configuration
  const domainTable = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0'
      }
    },
    muiTableContainerProps: {
      sx: { maxHeight: '600px' }
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      }
    },

    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        <Button
          variant="outlined"
          size="small"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={commonButtonStyle}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          size="small"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={commonButtonStyle}
        >
          Sort
        </Button>
        <Button
          disabled={table.getSelectedRowModel().rows.length === 0}
          variant="outlined"
          startIcon={<PanToolAltOutlined />}
          onClick={() => {
            setOpenAllocate(true);
            const selectedIds = table.getSelectedRowModel().rows.map(
              (row) => row.original.id
            );
            setIdds(selectedIds);
          }}
          sx={commonButtonStyle}
        >
          Allocate
        </Button>

        <Box flexGrow={1} />

        <TextField
          placeholder="Search leads..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={searchFieldStyles}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon 
                  sx={{ 
                    fontSize: '1rem', 
                    color: '#8797A8',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#6b7a8a',
                    },
                  }}
                  onClick={handleSearch} 
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Non Allocated Leads
          </Typography>
        </Stack>

        {/* Snackbar notifications */}
        <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setUpsuc(false)}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Domains Uploaded!
          </Alert>
        </Snackbar>
        
        <Snackbar 
          open={upfail} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setUpfail(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Domain Not Uploaded!
          </Alert>
        </Snackbar>

        <Snackbar 
          open={upsuc1} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setUpsuc1(false)}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            Allotted!
          </Alert>
        </Snackbar>

        <Snackbar 
          open={upfail1} 
          autoHideDuration={2000} 
          anchorOrigin={{ vertical: "top", horizontal: "center" }} 
          onClose={() => setUpfail1(false)}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Allotted!
          </Alert>
        </Snackbar>

        {/* Just the table without TabContext wrapper */}
        <Box>
          <MaterialReactTable table={domainTable} />
        </Box>

        {/* Keep existing dialogs */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />
          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />
          <LeadAllocationDialog 
            open={openAllocate}
            onClose={handleCloseAllocate}
            selectedIds={idds}
            onSuccess={() => {
              setUpsuc1(true);
              fetchData();
            }}
            onError={() => setUpfail1(true)}
            onComplete={handleCloseAllocate}
            leadAgentsChildren={leadAgentsChildren}
          />
        </LocalizationProvider>
      </Stack>
    </ThemeProvider>
  );
}