import { Alert, Box, Button, IconButton, Snackbar, Stack, TextField, ThemeProvider, Tooltip, Typography, MenuItem, Select, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { theme } from "../Theme";
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { getDomainResearchData, setSocialAllocationsWork } from "../api";
import { Add, AddCircle, Facebook, Instagram, LinkedIn, Remove, RemoveCircle, Twitter, Upload, X } from "@mui/icons-material";

let createLeadRow = () => {
  return {
    domain: "",
    description: "",
    copyright: "",
    priorityOrder: "",
    isInfluencer: false,
    noSocialsAvailable: false,
    siteStatus: "active",
    socials: [{ platform: "Linkedin", url: "", followers: "", employees: "", status: "", username: "" }]
  }
}

const convertToInteger = (inputText) => {
  // Trim the input to remove extra spaces
  const trimmedInput = inputText.trim();
  if (trimmedInput.length == 0)
    return 0;
  if (trimmedInput.toLowerCase().endsWith('k')) {
    const numberPart = parseFloat(trimmedInput.slice(0, -1));
    return Math.round(numberPart * 1000);
  } else {
    return parseInt(trimmedInput.replace(/,/g, ''), 10);
  }
};

let createCSocialsRow = () => {
  return { platform: "Linkedin", url: "", followers: "", employees: "", status: "" }
}

const SocialIconButton = memo(({ plat, addSocialField, i1, i2, i3, c }) => {
  const platforms = ["Linkedin", "Instagram", "Facebook", "X"];
  const icons = {
    Linkedin: <LinkedIn sx={{ color: '#0077b5' }} />,
    Instagram: <Instagram sx={{ color: '#d62976' }} />,
    Facebook: <Facebook sx={{ color: '#316FF6' }} />,
    X: <X sx={{ color: 'black' }} />
  };
  const [platform, setPlatform] = useState(plat);
  
  useEffect(() => { 
    setPlatform(plat) 
  }, [plat]);

  const handleIconClick = () => {
    const currentIndex = platforms.indexOf(platform);
    const nextIndex = (currentIndex + 1) % platforms.length;
    setPlatform(platforms[nextIndex]);
    addSocialField(i1, i2, i3, c, platforms[nextIndex]);
  };

  return (
    <IconButton size="small" onClick={handleIconClick}>
      {icons[platform]}
    </IconButton>
  );
});

const AddLeadButton = memo(({ addLead, i1, disabled }) => {
  return (
    <IconButton
      onClick={() => addLead(i1)}
      disabled={disabled}
      sx={{
        color: '#1976d2',
        '&:hover': {
          backgroundColor: 'rgba(25, 118, 210, 0.04)'
        },
        '&.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.26)'
        }
      }}
    >
      <AddCircle />
    </IconButton>
  );
});

const LeadSection = memo(({ row, addRowC, subRowC, setLeadName, addLead, setCp, setDesc, setLeadStatus, setIsInfluencer, setNoSocials, addSocialField, subRowL, setPriority, disabled }) => {
  return (
    <Box sx={{
      opacity: disabled ? 0.5 : 1,
      pointerEvents: disabled ? 'none' : 'auto'
    }}>
      {row.original.socialWork.topLeads.map((lead, i2) => (
        <Stack key={i2} direction='column' spacing={1} sx={{ mb: 1 }}>
          <Stack direction='row' spacing={1} alignItems="center">
            {/* Lead Info Section */}
            <Stack direction='column' spacing={1}>
              <TextField
                placeholder="Domain"
                value={lead.domain || ""}
                onChange={(e) => setLeadName(row.index, i2, e.target.value)}
                size="small"
                variant='outlined'
                margin="normal"
                autoCorrect={false}
                InputProps={{ sx: { fontSize: 12, height: 28 } }}
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  padding: 0,
                  margin: 0,
                  width: '160px'
                }}
              />
              <TextField
                placeholder="Copyright"
                value={lead.copyright || ""}
                onChange={(e) => setCp(row.index, i2, e.target.value)}
                size="small"
                variant='outlined'
                margin="normal"
                autoCorrect={false}
                InputProps={{ sx: { fontSize: 12, height: 28 } }}
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  padding: 0,
                  margin: 0,
                  width: '160px'
                }}
              />
            </Stack>

            {/* Description Section */}
            <Stack direction='column' spacing={1}>
              <TextField
                placeholder="Description"
                value={lead.description || ""}
                onChange={(e) => setDesc(row.index, i2, e.target.value)}
                size="small"
                variant='outlined'
                margin="normal"
                autoCorrect={false}
                InputProps={{ sx: { fontSize: 12, height: 28 } }}
                sx={{
                  '& legend': { display: 'none' },
                  '& fieldset': { top: 0 },
                  padding: 0,
                  margin: 0,
                  width: '200px'
                }}
              />
              <Stack direction='row' spacing={1} alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lead.isInfluencer || false}
                      size="small"
                      onChange={(e) => setIsInfluencer(row.index, i2, e.target.checked)}
                      sx={{
                        padding: '2px',
                        '& .MuiSvgIcon-root': { fontSize: 18 }
                      }}
                    />
                  }
                  label={<Typography variant="caption">INF</Typography>}
                  sx={{
                    margin: 0,
                    height: 25,
                    '& .MuiFormControlLabel-label': { marginLeft: '2px' }
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={lead.noSocialsAvailable || false}
                      size="small"
                      onChange={(e) => setNoSocials(row.index, i2, e.target.checked)}
                      sx={{
                        padding: '2px',
                        '& .MuiSvgIcon-root': { fontSize: 18 }
                      }}
                    />
                  }
                  label={<Typography variant="caption">NS</Typography>}
                  sx={{
                    margin: 0,
                    height: 25,
                    '& .MuiFormControlLabel-label': { marginLeft: '2px' }
                  }}
                />
                <Select
                  value={lead.siteStatus || "active"}
                  onChange={(e) => setLeadStatus(row.index, i2, e.target.value)}
                  size="small"
                  sx={{
                    height: 25,
                    fontSize: 12,
                    minWidth: 80,
                    '& .MuiSelect-select': {
                      padding: '2px 24px 2px 8px',
                      height: '21px',
                      lineHeight: '21px',
                    },
                  }}
                >
                  <MenuItem value="active" sx={{ fontSize: 12, height: 25 }}>Active</MenuItem>
                  <MenuItem value="inactive" sx={{ fontSize: 12, height: 25 }}>Inactive</MenuItem>
                  <MenuItem value="zero" sx={{ fontSize: 12, height: 25 }}>Zero</MenuItem>
                </Select>
              </Stack>
            </Stack>

            {/* Socials Section */}
            {!lead.noSocialsAvailable && 
            (<Stack direction='column' spacing={1}>
            {lead.socials.map((social, i3) => (
              <Stack key={i3} direction='row' spacing={1} alignItems="center">
                <SocialIconButton
                  plat={social.platform}
                  addSocialField={addSocialField}
                  i1={row.index}
                  i2={i2}
                  i3={i3}
                  c="platform"
                />
                <TextField
                  placeholder="URL"
                  value={social.url || ""}
                  onChange={(e) => addSocialField(row.index, i2, i3, "url", e.target.value)}
                  size="small"
                  variant='outlined'
                  margin="normal"
                  autoCorrect={false}
                  InputProps={{ sx: { fontSize: 12, height: 25 } }}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    padding: 0,
                    margin: 0,
                    width: '200px'
                  }}
                />
                <TextField
                  placeholder="Followers"
                  value={social.followers || ""}
                  onChange={(e) => addSocialField(row.index, i2, i3, "followers", e.target.value)}
                  size="small"
                  variant='outlined'
                  margin="normal"
                  autoCorrect={false}
                  InputProps={{ sx: { fontSize: 12, height: 25 } }}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    padding: 0,
                    margin: 0,
                    width: '80px'
                  }}
                />
                <TextField
                  placeholder="Employees"
                  value={social.employees || ""}
                  onChange={(e) => addSocialField(row.index, i2, i3, "employees", e.target.value)}
                  size="small"
                  variant='outlined'
                  margin="normal"
                  autoCorrect={false}
                  InputProps={{ sx: { fontSize: 12, height: 25 } }}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    padding: 0,
                    margin: 0,
                    width: '80px'
                  }}
                />
                <TextField
                  placeholder="Username"
                  value={social.username || ""}
                  onChange={(e) => addSocialField(row.index, i2, i3, "username", e.target.value)}
                  size="small"
                  variant='outlined'
                  margin="normal"
                  autoCorrect={false}
                  InputProps={{ sx: { fontSize: 12, height: 25 } }}
                  sx={{
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    padding: 0,
                    margin: 0,
                    width: '120px'
                  }}
                />
                <Select
                  value={social.status || ""}
                  onChange={(e) => addSocialField(row.index, i2, i3, "status", e.target.value)}
                  size="small"
                  sx={{
                    height: 25,
                    fontSize: 12,
                    minWidth: 80,
                    '& .MuiSelect-select': {
                      padding: '2px 24px 2px 8px',
                      height: '21px',
                      lineHeight: '21px',
                    },
                  }}
                >
                  <MenuItem value="" disabled style={{ fontSize: 12, height: 25 }}><em>Status</em></MenuItem>
                  <MenuItem value="active" style={{ fontSize: 12, height: 25 }}>Active</MenuItem>
                  <MenuItem value="inactive" style={{ fontSize: 12, height: 25 }}>Inactive</MenuItem>
                  <MenuItem value="zero" style={{ fontSize: 12, height: 25 }}>Zero</MenuItem>
                </Select>
                <IconButton
                  size="small"
                  onClick={() => subRowC(row.index, i2, i3)}
                  disabled={lead.socials.length <= 1}
                  sx={{ padding: 0 }}
                >
                  <Remove fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => addRowC(row.index, i2)}
                  sx={{ padding: 0 }}
                >
                  <Add fontSize="small" />
                </IconButton>
              </Stack>
            ))}
            </Stack>)}

            {/* Remove Lead Button */}
            <IconButton
              size="small"
              onClick={() => subRowL(row.index, i2)}
              sx={{ padding: 0 }}
            >
              <RemoveCircle fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      ))}
    </Box>
  );
});

const ResearchDialog = ({ open, onClose, domainId, onSuccess }) => {
  const [domainData, setDomainData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [openF, setOpenF] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    console.log("ggggggggg",domainId)
    if (open && domainId) {
      fetchDomainData();
    }
  }, [open, domainId]);

  const fetchDomainData = async () => {
    try {
      setLoading(true);
      const response = await getDomainResearchData(domainId);
      
      // Initialize data structure if needed
      if (!response.data.socialWork) {
        response.data.socialWork = { 
          topLeads: [],
          noLeadsAvailable: false
        };
      }
      
      // Ensure each lead has the required structure
      if (response.data.socialWork.topLeads.length === 0) {
        response.data.socialWork.topLeads = [createLeadRow()];
      } else {
        response.data.socialWork.topLeads = response.data.socialWork.topLeads.map(lead => ({
          ...lead,
          socials: lead.socials && lead.socials.length > 0 
            ? lead.socials 
            : [createCSocialsRow()]
        }));
      }
      
      setDomainData(response.data);
    } catch (error) {
      console.error("Error fetching domain data:", error);
      onClose();
      setOpenF(true);
    } finally {
      setLoading(false);
    }
  };

  const addRowC = useCallback((i1, i2) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].socials = [
        ...updatedData.socialWork.topLeads[i2].socials, 
        createCSocialsRow()
      ];
      return updatedData;
    });
  }, []);

  const subRowC = useCallback((i1, i2, i3) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].socials = [
        ...updatedData.socialWork.topLeads[i2].socials.slice(0, i3),
        ...updatedData.socialWork.topLeads[i2].socials.slice(i3 + 1)
      ];
      return updatedData;
    });
  }, []);

  const subRowL = useCallback((i1, i2) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads = [
        ...updatedData.socialWork.topLeads.slice(0, i2),
        ...updatedData.socialWork.topLeads.slice(i2 + 1)
      ];
      return updatedData;
    });
  }, []);

  const setCp = useCallback((i1, i2, c) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].copyright = c;
      return updatedData;
    });
  }, []);

  const setDesc = useCallback((i1, i2, c) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].description = c;
      return updatedData;
    });
  }, []);

  const addSocialField = useCallback((i1, i2, i3, c, val) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].socials[i3][c] = val;
      return updatedData;
    });
  }, []);

  const setLeadName = useCallback((i1, i2, inputValue) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].domain = inputValue;
      return updatedData;
    });
  }, []);

  const addLead = useCallback(() => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads = [
        ...updatedData.socialWork.topLeads, 
        createLeadRow()
      ];
      return updatedData;
    });
  }, []);

  const setPriority = useCallback((i1, i2, value) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].priorityOrder = value;
      return updatedData;
    });
  }, []);

  const setLeadStatus = useCallback((i1, i2, status) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].siteStatus = status;
      return updatedData;
    });
  }, []);

  const setIsInfluencer = useCallback((i1, i2, checked) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].isInfluencer = checked;
      return updatedData;
    });
  }, []);

  const setNoSocials = useCallback((i1, i2, checked) => {
    setDomainData((prevData) => {
      const updatedData = { ...prevData };
      updatedData.socialWork.topLeads[i2].noSocialsAvailable = checked;
      return updatedData;
    });
  }, []);

  const handleNoLeadsChange = useCallback((event) => {
    setDomainData((prevData) => ({
      ...prevData,
      socialWork: {
        ...prevData.socialWork,
        noLeadsAvailable: event.target.checked
      }
    }));
  }, []);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      
      // Format data similar to LeadsHub structure
      const dataToSubmit = [{
        ...domainData,
        socialWork: {
          ...domainData.socialWork,
          topLeads: domainData.socialWork.topLeads.map(lead => ({
            ...lead,
            socials: lead.socials.map(social => ({
              ...social,
              followers: convertToInteger(social.followers),
              employees: convertToInteger(social.employees)
            }))
          }))
        }
      }];
      
      const response = await setSocialAllocationsWork(dataToSubmit);
      
      if (response.data > 0) {
        setOpenS(true);
        if (onSuccess) {
          onSuccess(response.data);
        }
        setTimeout(() => {
          onClose();
        }, 1500);
      } else {
        setOpenF(true);
      }
    } catch (error) {
      console.error("Error submitting research data:", error);
      setOpenF(true);
    } finally {
      setSubmitting(false);
    }
  };

  if (!domainData) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            maxHeight: '90vh',
            width: '900px'
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          pb: 1 
        }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            Research: {domainData.presentDomain?.domain}
          </Typography>
          <Stack direction="row" spacing={2} alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={domainData.socialWork.noLeadsAvailable || false}
                  size="small"
                  onChange={handleNoLeadsChange}
                />
              }
              label="No Leads Available"
            />
            <Button
              startIcon={<AddCircle />}
              onClick={addLead}
              variant="outlined"
              disabled={domainData.socialWork.noLeadsAvailable}
              size="small"
            >
              Add Lead
            </Button>
          </Stack>
        </DialogTitle>
        
        <DialogContent>
          <Snackbar open={openS} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setOpenS(false); }}>
            <Alert severity='success' sx={{ width: '100%' }}>
              Research data submitted successfully!
            </Alert>
          </Snackbar>
          <Snackbar open={openF} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setOpenF(false); }}>
            <Alert severity="error" sx={{ width: '100%' }}>
              Operation failed!
            </Alert>
          </Snackbar>
          
          <Box sx={{ mt: 2 }}>
            <Box sx={{ 
              opacity: domainData.socialWork.noLeadsAvailable ? 0.5 : 1,
              pointerEvents: domainData.socialWork.noLeadsAvailable ? 'none' : 'auto'
            }}>
              <LeadSection
                row={{ 
                  original: domainData, 
                  index: 0 
                }}
                addRowC={addRowC}
                subRowC={subRowC}
                setLeadName={setLeadName}
                addLead={addLead}
                setCp={setCp}
                setDesc={setDesc}
                setLeadStatus={setLeadStatus}
                setIsInfluencer={setIsInfluencer}
                setNoSocials={setNoSocials}
                addSocialField={addSocialField}
                subRowL={subRowL}
                setPriority={setPriority}
                disabled={domainData.socialWork.noLeadsAvailable}
              />
            </Box>
          </Box>
        </DialogContent>
        
        <DialogActions sx={{ p: 2 }}>
          <Button onClick={onClose} disabled={submitting}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained" 
            disabled={submitting}
            startIcon={submitting ? null : <Upload />}
          >
            {submitting ? 'Submitting...' : 'Submit Research'}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default ResearchDialog; 