import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button,
  Box, Stack, TextField, Select, MenuItem, IconButton,
  Tooltip, Snackbar, Alert, Divider, FormControlLabel, Checkbox
} from '@mui/material';
import {
  Add, Remove, LinkedIn, Instagram, Facebook, X as XIcon,
  AddCircle, RemoveCircle, Edit, Save
} from '@mui/icons-material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { reviewLeadsAndSocials } from '../api';

const createLeadRow = () => ({
  id: null,
  domain: "",
  description: "",
  copyright: "",
  isIrrelevant: false,
  isWrong: false,
  isNewlyAdded: true,
  missedStatus: 1,
  noSocialsAvailable: false,
  isInfluencer: false,
  leadStatus: "active",
  socials: [{
    id: null,
    platform: "Linkedin",
    url: "",
    followers: 0,
    employees: 0,
    status: "",
    isIrrelevant: false,
    isWrong: false,
    missedStatus: 1,
    userFullName: null
  }]
});

const createSocialRow = () => ({
  id: null,
  platform: "Linkedin",
  url: "",
  followers: 0,
  employees: 0,
  status: "",
  isIrrelevant: false,
  isWrong: false,
  missedStatus: 1,
  userFullName: null
});

// Input Components
const Leadtb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      required={!props.parentIsIrrelevant}
      placeholder="lead.com *"
      value={inputValue || ''}
      size='small'
      disabled={!props.isEditing && !props.isNewlyAdded}
      onChange={handleInputChange}
      onBlurCapture={() => {
        props.handleLeadChange(props.index, 'domain', inputValue);
      }}
      variant='outlined'
      margin="normal"
      autoCorrect={false}
      InputProps={{
        sx: {
          fontSize: 14,
          height: 30,
          backgroundColor: props.isEditing || (props.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
        }
      }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0
      }}
      error={!props.value?.trim() && !props.parentIsIrrelevant}
    />
  );
});

const Desctb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      required={!props.parentIsIrrelevant}
      placeholder="Description *"
      value={inputValue || ''}
      size='small'
      disabled={!props.isEditing && !props.isNewlyAdded}
      onChange={handleInputChange}
      onBlurCapture={() => {
        props.handleLeadChange(props.index, 'description', inputValue);
      }}
      variant='outlined'
      margin="normal"
      autoCorrect={false}
      InputProps={{
        sx: {
          fontSize: 14,
          height: 30,
          backgroundColor: props.isEditing || (props.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
        }
      }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0
      }}
      error={!props.value?.trim() && !props.parentIsIrrelevant}
    />
  );
});

const CopyRighttb = memo((props) => {
  const [inputValue, setInputValue] = useState(props.value);

  useEffect(() => {
    setInputValue(props.value);
  }, [props.value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <TextField
      required={!props.parentIsIrrelevant}
      placeholder="Copyright *"
      value={inputValue || ''}
      size='small'
      disabled={!props.isEditing && !props.isNewlyAdded}
      onChange={handleInputChange}
      onBlurCapture={() => {
        props.handleLeadChange(props.index, 'copyright', inputValue);
      }}
      variant='outlined'
      margin="normal"
      autoCorrect={false}
      InputProps={{
        sx: {
          fontSize: 14,
          height: 30,
          backgroundColor: props.isEditing || (props.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
        }
      }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0
      }}
      error={!props.value?.trim() && !props.parentIsIrrelevant}
    />
  );
});

const RowActionsL = memo(({ index, total, onRemove, missedStatus }) => {
  return (
    <Box sx={{ display: 'flex', gap: '1rem' }}>
      {missedStatus === 1 && !(index === 0 && total === 1) && (
        <Tooltip title="Remove">
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            color="primary"
            onClick={onRemove}
          >
            <RemoveCircle fontSize="small" sx={{ padding: 0 }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
});

const SocialIconButton = memo(({ plat, handleSocialChange, r1, rowIndex }) => {
  const platforms = ["Linkedin", "Instagram", "Facebook", "X"];
  const icons = {
    Linkedin: <LinkedIn sx={{ color: '#0077b5' }} />,
    Instagram: <Instagram sx={{ color: '#d62976' }} />,
    Facebook: <Facebook sx={{ color: '#316FF6' }} />,
    X: <XIcon sx={{ color: 'black' }} />
  };

  const handleIconClick = () => {
    const currentIndex = platforms.indexOf(plat);
    console.log('Current index:', currentIndex);
    const nextIndex = (currentIndex + 1) % platforms.length;
    console.log('Next platform:', platforms[nextIndex]);
    handleSocialChange(r1.index, rowIndex, 'platform', platforms[nextIndex]);
  };

  const platformKey = platforms.find(p => p.toLowerCase() === plat?.toLowerCase()) || "Linkedin";

  return (
    <IconButton size="small" onClick={handleIconClick}>
      {icons[platformKey]}
    </IconButton>
  );
});

// Update StatusButtons for leads (with edit icon)
const LeadStatusButtons = memo(({ isIrrelevant, onStatusChange, onEditToggle, isEditing, missedStatus }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {missedStatus !== 1 && (
      <FormControlLabel
        control={
          <Checkbox
            checked={isIrrelevant}
            onChange={() => {
              onStatusChange('isIrrelevant', !isIrrelevant);
              if (!isIrrelevant) {
                onEditToggle(false);
              }
            }}
            size="small"
            sx={{ padding: '4px' }}
          />
        }
        label="IR"
        sx={{
          margin: 0,
          '.MuiFormControlLabel-label': {
            fontSize: '0.875rem'
          }
        }}
      />
    )}
      <Tooltip title={isEditing ? "Save" : "Edit"}>
        <IconButton
          size="small"
          onClick={() => onEditToggle(!isEditing)}
          disabled={isIrrelevant}
          sx={{ padding: '4px' }}
        >
          {isEditing ? <Save fontSize="small" /> : <Edit fontSize="small" />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
});

// Separate StatusButtons for socials
const SocialStatusButtons = memo(({ isIrrelevant, isWrong, onStatusChange, disabled, parentIsIrrelevant, missedStatus}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    if (!isEditing) {
      onStatusChange("isWrong", true);
    }
    setIsEditing(!isEditing);
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip title={isEditing ? "Save" : "Edit"}>
        <IconButton
          size="small"
          onClick={handleEditClick}
          disabled={disabled || isIrrelevant || parentIsIrrelevant}
          sx={{ padding: '2px' }}
        >
          {isEditing ? <Save fontSize="small" /> : <Edit fontSize="small" />}
        </IconButton>
      </Tooltip>
      {!missedStatus && (
      <FormControlLabel
        control={
          <Checkbox
            checked={isIrrelevant}
            disabled={disabled || isWrong || parentIsIrrelevant}
            onChange={() => onStatusChange('isIrrelevant', !isIrrelevant)}
            size="small"
            sx={{ padding: '4px' }}
          />
        }
        label="IR"
        sx={{
          margin: 0,
          '.MuiFormControlLabel-label': {
            fontSize: '0.875rem'
          }
        }}
      />)}
    </Stack>
  );
});

// Add a new component for the status dropdown
const StatusDropdown = memo(({ value, disabled, parentIsIrrelevant, r1, rowIndex, handleSocialChange }) => {
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    handleSocialChange(r1.index, rowIndex, 'status', newValue);
  };

  return (
    <Select
      value={inputValue}
      onChange={handleInputChange}
      size="small"
      variant="outlined"
      displayEmpty
      disabled={disabled}
      // disabled={disabled || parentIsIrrelevant || (!r1.original.isEditing && (r1.original.missedStatus ?? 0) !== 1)}
      sx={{
        fontSize: 13,
        height: 25,
        width: '100%',
        backgroundColor: r1.original.isEditing || (r1.original.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5',
        '& .MuiSelect-select': {
          padding: '2px 10px 2px 8px',
          height: '21px',
          lineHeight: '21px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
          },
        },
      }}
    >
      <MenuItem value="" disabled style={{ fontSize: 13, height: 25 }}>
        <em>Status</em>
      </MenuItem>
      <MenuItem value="active" style={{ fontSize: 13, height: 25 }}>Active</MenuItem>
      <MenuItem value="inactive" style={{ fontSize: 13, height: 25 }}>Inactive</MenuItem>
      <MenuItem value="zero" style={{ fontSize: 13, height: 25 }}>Zero</MenuItem>
    </Select>
  );
});

// Update SocialsSection component
const SocialsSection = memo(({ row, socialsData, r1, handleSocialChange, parentIsIrrelevant }) => {
  const [sData, setSdata] = useState(socialsData);

  useEffect(() => {
    setSdata(socialsData);
  }, [socialsData]);

  const handleAddSocial = useCallback((leadIndex) => {
    const newSocial = createSocialRow();
    handleSocialChange(leadIndex, socialsData.length, 'add', newSocial);
  }, [handleSocialChange, socialsData.length]);

  const handleRemoveSocial = useCallback((leadIndex, socialIndex) => {
    handleSocialChange(leadIndex, socialIndex, 'remove');
  }, [handleSocialChange]);

  const socialsColumns = useMemo(() => {
    const baseColumns = [
      {
        id: 'platform',
        accessorKey: 'platform',
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => (
          <Box width='100%'>
            <SocialIconButton
              plat={row.original.platform}
              r1={r1}
              rowIndex={row.index}
              handleSocialChange={handleSocialChange}
              disabled={parentIsIrrelevant || (!row.original.isWrong && !r1.original.isEditing && !r1.original.isNewlyAdded)}
            />
          </Box>
        )
      },
      {
        id: 'url',
        header: 'URL',
        size: 100,
        maxSize: 100,
        Cell: ({ row }) => (
          <TextField
            placeholder="URL"
            value={row.original.url || ''}
            disabled={parentIsIrrelevant || (!row.original.isWrong && !r1.original.isEditing && !r1.original.isNewlyAdded)}
            onChange={(e) => handleSocialChange(r1.index, row.index, 'url', e.target.value)}
            size="small"
            variant="outlined"
            InputProps={{
              sx: {
                fontSize: 13,
                height: 25,
                backgroundColor: r1.original.isEditing || (row.original.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
              }
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              padding: 0,
              margin: 0
            }}
          />
        )
      },
      {
        id: 'followers',
        header: 'Followers',
        size: 70,
        maxSize: 70,
        Cell: ({ row }) => (
          <TextField
            placeholder="Followers"
            type="number"
            value={row.original.followers || 0}
            disabled={parentIsIrrelevant || (!row.original.isWrong && !r1.original.isEditing && !r1.original.isNewlyAdded)}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
              handleSocialChange(r1.index, row.index, 'followers', value);
            }}
            size="small"
            variant="outlined"
            InputProps={{
              sx: {
                fontSize: 13,
                height: 25,
                backgroundColor: r1.original.isEditing || (row.original.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
              }
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              padding: 0,
              margin: 0,
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
              "& input[type=number]": { MozAppearance: "textfield" }
            }}
          />
        )
      },
      {
        id: 'employees',
        header: 'Employees',
        size: 70,
        maxSize: 70,
        Cell: ({ row }) => (
          <TextField
            placeholder="Employees"
            type="number"
            value={row.original.employees || 0}
            disabled={parentIsIrrelevant || (!row.original.isWrong && !r1.original.isEditing && !r1.original.isNewlyAdded)}
            onChange={(e) => {
              const value = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
              handleSocialChange(r1.index, row.index, 'employees', value);
            }}
            size="small"
            variant="outlined"
            InputProps={{
              sx: {
                fontSize: 13,
                height: 25,
                backgroundColor: r1.original.isEditing || (row.original.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
              }
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              padding: 0,
              margin: 0,
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": { display: "none" },
              "& input[type=number]": { MozAppearance: "textfield" }
            }}
          />
        )
      },
      {
        id: 'userFullName',
        header: 'User Full Name',
        size: 90,
        maxSize: 90,
        Cell: ({ row }) => (
          <TextField
            placeholder="User Full Name"
            value={row.original.userFullName || ''}
            disabled={parentIsIrrelevant || (!row.original.isWrong && !r1.original.isEditing && !r1.original.isNewlyAdded)}
            onChange={(e) => handleSocialChange(r1.index, row.index, 'userFullName', e.target.value)}
            size="small"
            variant="outlined"
            InputProps={{
              sx: {
                fontSize: 13,
                height: 25,
                backgroundColor: r1.original.isEditing || (row.original.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
              }
            }}
            sx={{
              '& legend': { display: 'none' },
              '& fieldset': { top: 0 },
              padding: 0,
              margin: 0
            }}
          />
        )
      },
      {
        id: 'status',
        header: 'Status',
        size: 70,
        maxSize: 70,
        Cell: ({ row }) => (
          <StatusDropdown
            value={row.original.status}
            disabled={parentIsIrrelevant || (!row.original.isWrong && !r1.original.isEditing && !r1.original.isNewlyAdded)}
            parentIsIrrelevant={parentIsIrrelevant}
            r1={r1}
            rowIndex={row.index}
            handleSocialChange={handleSocialChange}
          />
        )
      },
    ];

    // Only add socialStatus column if missedStatus is not 1
    // if ((r1.original.missedStatus ?? 0) !== 1) {
      baseColumns.push({
        id: 'socialStatus',
        header: 'Status',
        size: 80,
        Cell: ({ row }) => (
          <SocialStatusButtons
            isIrrelevant={row.original.isIrrelevant}
            isWrong={row.original.isWrong}
            onStatusChange={(field, value) => handleSocialChange(r1.index, row.index, field, value)}
            disabled={parentIsIrrelevant}
            parentIsIrrelevant={parentIsIrrelevant}
            missedStatus={(r1.original.missedStatus ?? 0) !== 1}
          />
        )
      });
    // }

    return baseColumns;
  }, [r1, handleSocialChange, parentIsIrrelevant]);

  const socialsTable = useMaterialReactTable({
    columns: socialsColumns,
    data: sData || [],
    enableExpandAll: true,
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => {
      const isLastSocial = row.index === socialsData.length - 1;
      const missedStatus = row.original.missedStatus ?? 0;

      return (
        <Box sx={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'center',
        }}>
          {missedStatus === 0 && isLastSocial ? (
            <Tooltip title="Add">
              <IconButton
                size="small"
                sx={{ padding: 0 }}
                color="primary"
                onClick={() => handleAddSocial(r1.index)}
              >
                <Add fontSize="small" sx={{ padding: 0 }} />
              </IconButton>
            </Tooltip>
          ) : null}
          {missedStatus === 1 ? (
            <>
              <Tooltip title="Add">
                <IconButton
                  size="small"
                  sx={{ padding: 0 }}
                  color="primary"
                  onClick={() => handleAddSocial(r1.index)}
                >
                  <Add fontSize="small" sx={{ padding: 0 }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remove">
                <IconButton
                  size="small"
                  sx={{ padding: 0 }}
                  color="primary"
                  onClick={() => handleRemoveSocial(r1.index, row.index)}
                >
                  <Remove fontSize="small" sx={{ padding: 0 }} />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </Box>
      );
    },
    muiTableBodyCellProps: {
      sx: {
        '&.css-nvubc0-MuiTableCell-root': {
          width: '20px !important',
          minWidth: '10px !important',
          padding: '0px 4px'
        }
      }
    },
    layoutMode: 'grid-no-grow',
    enableBottomToolbar: false,
    initialState: { density: 'compact' },
    muiTableBodyProps: { sx: { backgroundColor: '#f0f0ed' } },
    muiTablePaperProps: { elevation: 0 },
    muiTableBodyRowProps: { sx: { padding: 0, margin: 0, height: 30 } },
    enableTopToolbar: false,
    enableTableHead: false
  });

  return (
    <Box>
      {(!sData || sData.length === 0) ? (
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          height: '40px',
          backgroundColor: '#f0f0ed'
        }}>
          <Tooltip title="Add Social">
            <IconButton
              size="small"
              color="primary"
              onClick={() => handleAddSocial(r1.index)}
            >
              <Add fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <MaterialReactTable table={socialsTable} />
      )}
    </Box>
  );
});

const NoSocialsCheckbox = memo((props) => {
  const [checked, setChecked] = useState(props.value);

  useEffect(() => {
    console.log("dfdfdffdfdfdf", props);
    setChecked(props.value);
  }, [props.value]);

  const handleCheckboxChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    props.handleLeadChange(props.index, 'noSocialsAvailable', newValue);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          size="small"
          disabled={!props.isEditing && !props.isNewlyAdded}
          sx={{ padding: '4px' }}
        />
      }
      label="No Socials"
      sx={{ margin: 0, '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
    />
  );
});

const InfluencerCheckbox = memo((props) => {
  const [checked, setChecked] = useState(props.value);

  useEffect(() => {
    setChecked(props.value);
  }, [props.value]);

  const handleCheckboxChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    props.handleLeadChange(props.index, 'isInfluencer', newValue);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleCheckboxChange}
          size="small"
          disabled={!props.isEditing && !props.isNewlyAdded}
          sx={{ padding: '4px' }}
        />
      }
      label="Influencer"
      sx={{ margin: 0, '.MuiFormControlLabel-label': { fontSize: '0.875rem' } }}
    />
  );
});

const LeadStatusDropdown = memo((props) => {
  const [selectedValue, setSelectedValue] = useState(props.value);

  useEffect(() => {
    setSelectedValue(props.value);
  }, [props.value]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    props.handleLeadChange(props.index, 'leadStatus', newValue);
  };

  return (
    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      size="small"
      disabled={!props.isEditing && !props.isNewlyAdded}
      sx={{
        height: 25,
        fontSize: 13,
        minWidth: 80,
        backgroundColor: props.isEditing || (props.missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5',
        '& .MuiSelect-select': {
          padding: '2px 24px 2px 8px',
          height: '21px',
          lineHeight: '21px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }
      }}
    >
      <MenuItem value="active" sx={{ fontSize: 13, height: 25 }}>Active</MenuItem>
      <MenuItem value="inactive" sx={{ fontSize: 13, height: 25 }}>Inactive</MenuItem>
      <MenuItem value="zero" style={{ fontSize: 13, height: 25 }}>Zero</MenuItem>
    </Select>
  );
});

const UserFullNameField = memo(({ value, leadIndex, socialIndex, handleSocialChange, isEditing, missedStatus, parentIsIrrelevant,isNewlyAdded }) => {
  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    handleSocialChange(leadIndex, socialIndex, 'userFullName', event.target.value);
  };

  return (
    <TextField
      placeholder="User Full Name"
      value={inputValue}
      size="small"
      disabled={parentIsIrrelevant || (!isEditing && !isNewlyAdded)}
      onChange={handleInputChange}
      variant="outlined"
      InputProps={{
        sx: {
          fontSize: 13,
          height: 25,
          backgroundColor: isEditing || (missedStatus ?? 0) === 1 ? 'white' : '#f5f5f5'
        }
      }}
      sx={{
        '& legend': { display: 'none' },
        '& fieldset': { top: 0 },
        padding: 0,
        margin: 0,
        width: '100%'
      }}
    />
  );
});

const NoLeadsCheckbox = memo(({ value, onChange }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          size="small"
          sx={{ padding: '4px' }}
        />
      }
      label="NL"
      sx={{
        margin: 0,
        '.MuiFormControlLabel-label': {
          fontSize: '0.875rem'
        }
      }}
    />
  );
});

const LeadSection = memo(({ leads, setLeads, handleLeadChange, handleSocialChange }) => {
  const [editingStates, setEditingStates] = useState(leads.map(() => false));

  const handleEditToggle = (index, newState) => {
    setEditingStates(prev => {
      const newStates = [...prev];
      newStates[index] = newState;
      // When enabling edit mode, mark as wrong internally
      if (newStates[index]) {
        handleLeadChange(index, 'isWrong', true);
      }
      return newStates;
    });
  };

  // Update input fields to respect parent irrelevant state
  const renderInputField = (Component, props, isParentIrrelevant) => (
    <Component
      {...props}
      disabled={isParentIrrelevant}
      isEditing={props.isEditing}
      isWrong={props.isWrong}
      isNewlyAdded={props.isNewlyAdded}
    />
  );

  const leadsColumns = useMemo(() => [
    {
      id: 'mainContent',
      size: 340,
      Cell: ({ row }) => {
        const isParentIrrelevant = row.original.isIrrelevant;
        const isEditing = editingStates[row.index];
        const missedStatus = row.original.missedStatus ?? 0;

        return (
          <Box sx={{ py: 0.5 }}>
            <Box sx={{ display: 'flex', mb: 0.5 }}>
              <Box sx={{ width: '200px' }}>
                {renderInputField(Leadtb, {
                  value: row.original.domain,
                  index: row.index,
                  handleLeadChange,
                  isEditing,
                  isWrong: row.original.isWrong,
                  missedStatus,
                  parentIsIrrelevant: isParentIrrelevant,
                  isNewlyAdded: row.original.isNewlyAdded
                }, isParentIrrelevant)}
              </Box>
              <Box sx={{ width: '120px' }}>
                {renderInputField(CopyRighttb, {
                  value: row.original.copyright,
                  index: row.index,
                  handleLeadChange,
                  isEditing,
                  isWrong: row.original.isWrong,
                  missedStatus,
                  parentIsIrrelevant: isParentIrrelevant,
                  isNewlyAdded: row.original.isNewlyAdded
                }, isParentIrrelevant)}
              </Box>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ width: '200px' }}>
                {renderInputField(Desctb, {
                  value: row.original.description,
                  index: row.index,
                  handleLeadChange,
                  isEditing,
                  isWrong: row.original.isWrong,
                  missedStatus,
                  parentIsIrrelevant: isParentIrrelevant,
                  isNewlyAdded: row.original.isNewlyAdded
                }, isParentIrrelevant)}
              </Box>
              {/* {missedStatus !== 1 && ( */}
                <Box sx={{ width: '120px' }}>
                  <LeadStatusButtons
                    isIrrelevant={isParentIrrelevant}
                    onStatusChange={(field, value) => handleLeadChange(row.index, field, value)}
                    onEditToggle={(newState) => handleEditToggle(row.index, newState)}
                    isEditing={isEditing}
                    missedStatus={missedStatus}
                  />
                </Box> 
              {/* )} */}
            </Box>
            <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: 1 }}>
              <NoSocialsCheckbox
                value={row.original.noSocialsAvailable}
                index={row.index}
                handleLeadChange={handleLeadChange}
                isWrong={row.original.isWrong}
                isEditing={isEditing}
                isNewlyAdded={row.original.isNewlyAdded}
              />
              <InfluencerCheckbox
                value={row.original.isInfluencer}
                index={row.index}
                handleLeadChange={handleLeadChange}
                isWrong={row.original.isWrong}
                isEditing={isEditing}
                isNewlyAdded={row.original.isNewlyAdded}
              />
              <LeadStatusDropdown
                value={row.original.leadStatus}
                index={row.index}
                handleLeadChange={handleLeadChange}
                isWrong={row.original.isWrong}
                isEditing={isEditing}
                isNewlyAdded={row.original.isNewlyAdded}
              />
            </Stack>
          </Box>
        );
      }
    },
    {
      id: 'socials',
      header: 'Socials',
      size: 590,
      Cell: ({ row }) => (
        <SocialsSection
          row={row}
          socialsData={row.original.socials}
          r1={row}
          handleSocialChange={handleSocialChange}
          parentIsIrrelevant={row.original.isIrrelevant}
        />
      )
    }
  ], [handleLeadChange, handleSocialChange, editingStates]);

  const leadTable = useMaterialReactTable({
    columns: leadsColumns,
    data: leads,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableTableHead: false,
    layoutMode: 'grid-no-grow',
    enableRowActions: true,
    positionActionsColumn: 'last',
    renderRowActions: ({ row }) => (
      <RowActionsL
        index={row.index}
        total={leads.length}
        onRemove={() => setLeads(leads.filter((_, i) => i !== row.index))}
        missedStatus={row.original.missedStatus}
      />
    ),
    initialState: {
      density: 'compact',
      pagination: { pageSize: 200, pageIndex: 0 }
    },
    muiTableBodyProps: { sx: { backgroundColor: 'whitesmoke', padding: 0 } },
    muiTableBodyRowProps: { sx: { backgroundColor: 'whitesmoke', padding: '4px 0' } },
    muiTablePaperProps: {
      elevation: 0,
      // sx: {
      //   '& .MuiTableContainer-root': {
      //     overflowX: 'hidden'
      //   }
      // }
    }
  });

  return (
    <Stack direction="column" spacing={1}>
      <MaterialReactTable table={leadTable} />
    </Stack>
  );
});

const QaAqLeadsDialog = ({ open, onClose, data, onSuccess }) => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reviewRemark, setReviewRemark] = useState('');
  const [noLeadsAvailable, setNoLeadsAvailable] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    if (data) {
      setLeads(data.socialLeads || []);
      setReviewRemark(data?.reviewRemark || '');
      setNoLeadsAvailable(data?.noLeadsAvailable || false);
    }
  }, [data]);

  const handleLeadChange = useCallback((index, field, value) => {
    setLeads(prevLeads => {
      const newLeads = [...prevLeads];
      newLeads[index][field] = value;
      return newLeads;
    });
  }, []);

  const handleSocialChange = useCallback((leadIndex, socialIndex, action, value) => {
    setLeads(prevLeads => {
      const newLeads = [...prevLeads];

      switch (action) {
        case 'add':
          newLeads[leadIndex].socials = [
            ...newLeads[leadIndex].socials,
            value
          ];
          break;
        case 'remove':
          newLeads[leadIndex].socials = newLeads[leadIndex].socials.filter(
            (_, index) => index !== socialIndex
          );
          break;
        default:
          // For updating fields like platform, url, followers, etc.
          newLeads[leadIndex].socials[socialIndex][action] = value;
      }

      return newLeads;
    });
  }, []);

  // Add new function to validate leads
  const validateLeads = useCallback(() => {
    if (noLeadsAvailable) return true;

    if (!leads || leads.length === 0) return false;

    return leads.every(lead => {
      // Check if required fields are filled
      return lead.domain?.trim() !== '' &&
        lead.description?.trim() !== '' &&
        lead.copyright?.trim() !== '';
    });
  }, [leads, noLeadsAvailable]);

  // Add state for form validity
  const [isValid, setIsValid] = useState(true);

  // Update validation whenever leads or noLeadsAvailable changes
  useEffect(() => {
    setIsValid(validateLeads());
  }, [leads, noLeadsAvailable, validateLeads]);

  const handleUpdate = async () => {
    if (!validateLeads()) {
      setSnackbar({
        open: true,
        message: 'Please fill in all required fields (Domain, Description, Copyright) for each lead',
        severity: 'warning'
      });
      return;
    }

    try {
      setLoading(true);
      await reviewLeadsAndSocials({
        id: data.acquShortlistedDomain.id,
        domain: data.acquShortlistedDomain.domain,
        socialWorkId: data.socialWorkId,
        reviewRemark: reviewRemark,
        noLeadsAvailable: noLeadsAvailable,
        socialLeads: leads.map(lead => ({
          ...lead,
          id: lead.id || null,
          socials: lead.socials.map(social => ({
            ...social,
            id: social.id || null
          }))
        }))
      });
      setSnackbar({
        open: true,
        message: 'Successfully updated leads and socials',
        severity: 'success'
      });
      if (onSuccess) onSuccess();
      setTimeout(() => onClose(), 1000);
    } catch (error) {
      console.error('Error updating leads and socials:', error);
      setSnackbar({
        open: true,
        message: 'Failed to update leads and socials',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Add an effect to handle noLeadsAvailable changes
  useEffect(() => {
    if (!noLeadsAvailable && (!leads || leads.length === 0)) {
      // Automatically add one missed lead when unchecking noLeadsAvailable
      setLeads([createLeadRow()]);
    }
  }, [noLeadsAvailable]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            maxHeight: '90vh',
            width: '900px'
          }
        }}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div>Review Leads & Socials for {data?.acquShortlistedDomain?.domain}</div>
          <Stack direction="row" spacing={2}>
            <NoLeadsCheckbox
              value={noLeadsAvailable}
              onChange={setNoLeadsAvailable}
            />
            <Button
              startIcon={<AddCircle />}
              onClick={() => setLeads([...leads, createLeadRow()])}
            >
              Add Missed Leads
            </Button>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ p: 1 }}>
          <LeadSection
            leads={leads}
            setLeads={setLeads}
            handleLeadChange={handleLeadChange}
            handleSocialChange={handleSocialChange}
          />
          <Box sx={{ mt: 2, px: 1 }}>
            <TextField
              fullWidth
              label="Review Remarks"
              multiline
              rows={2}
              value={reviewRemark}
              onChange={(e) => setReviewRemark(e.target.value)}
              placeholder="Enter your review remarks here..."
              variant="outlined"
              size="small"
              InputProps={{
                sx: { fontSize: 14 }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>Cancel</Button>
          <Button
            onClick={handleUpdate}
            variant="contained"
            disabled={loading || !isValid}
            color={isValid ? 'primary' : 'inherit'}
          >
            {loading ? 'Updating...' : 'Update'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};
export default QaAqLeadsDialog;
