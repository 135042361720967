import { Alert, Box, Button, Dialog, DialogContentText, Tabs,FormControlLabel, FormGroup, IconButton, Snackbar, Stack, Switch, Tab, ThemeProvider, Tooltip, Typography, TextField, InputAdornment, ToggleButtonGroup, ToggleButton, DialogTitle, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem, OutlinedInput, Checkbox, ListItemText, Chip } from "@mui/material";
import { theme } from "../Theme";
import React, { memo, useEffect, useState, useCallback, useMemo } from "react";
import {getAllocatedByMeP,getAllocatedByMeleads, getAllocatedByMeT, reallocateToProcessorP, reallocateToProcessorT, reallocateToTeamP, reallocateToTeamT ,reallocateLeadToProcessorP, getLeadAgentsChildren} from "../api";
import {Sort as SortIcon, Create, DoneOutline, Stop, FiberManualRecord, PanToolAltOutlined, Person, Groups } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import { useUser } from '../UserProvider';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

// Status Icon Component
const StatusIcon = memo(({ status }) => {
  const numericStatus = typeof status === 'number' ? status : (
    status?.toLowerCase() === 'not started' ? 0 :
    status?.toLowerCase() === 'in progress' ? 1 :
    status?.toLowerCase() === 'submitted' ? 2 :
    status?.toLowerCase() === 'approved' ? 3 : 0
  );

  switch (numericStatus) {
    case 0:
      return <Tooltip title="Not Started"><Stop /></Tooltip>;
    case 1:
      return <Tooltip title="In Progress"><Create color="primary" /></Tooltip>;
    case 2:
      return <Tooltip title="Submitted"><DoneOutline sx={{ color: "orange" }} /></Tooltip>;
    case 3:
      return <Tooltip title="Approved"><DoneOutline sx={{ color: "green" }} /></Tooltip>;
    default:
      return <Tooltip title="Not Started"><Stop /></Tooltip>;
  }
});

export default function AllocatedByMeLeads() {
  // Simplified state management
  const [domains, setDomains] = useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [idds, setIdds] = useState([]);
  const [leadAgentsChildren, setLeadAgentsChildren] = useState([]);

  // Pagination, sorting, filtering states
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');

  // Dialog states
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  
  // Snackbar states
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);


  const { user } = useUser();

  useEffect(() => {
    getLeadAgentsChildren().then((response) => {
      setLeadAgentsChildren(response.data);
    });
  }, []);

  // Common styles
  const commonButtonStyle = {
    textTransform: 'none',
    backgroundColor: '#8797A8',
    color: '#FFFFFF !important',
    height: '34px',
    padding: '4px 8px',
    minWidth: 'auto',
    '& .MuiButton-startIcon': {
      marginRight: '4px',
    },
    '&:hover': {
      backgroundColor: '#8797A8',
      opacity: 0.9,
      boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#8797A8',
      opacity: 0.7,
      color: '#FFFFFF !important',
    }
  };

  const searchFieldStyles = {
    width: '160px',
    '& .MuiOutlinedInput-root': {
      height: '28px',
      fontSize: '0.875rem',
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      '& fieldset': {
        borderColor: '#e0e0e0',
        borderRadius: '12px',
      },
      '&:hover fieldset': {
        borderColor: '#8797A8',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#8797A8',
      },
    },
  };

  // Handlers
  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  // Add handler for user selection
  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
  };


  const columns = useMemo(() => [
    {
      id: 'leadName',
      accessorKey: 'leadName',
      header: 'Lead Name',
      size: 120
    },
    {
      id: 'domainName',
      header: 'Domain Name',
      accessorFn: (row) => row.presentLead?.domainName ?? 'N/A',
      size: 120
    },
    {
      id: 'status',
      header: 'Status',
      accessorKey: 'status',
      Cell: ({ cell }) => <StatusIcon status={cell.getValue()} />,
      size: 80,
    },
    {
      id: 'dateOfAlloc',
      accessorKey: 'dateOfAlloc',
      header: 'Alloc Date',
      size: 100,
      accessorFn: (row) => new Date(row.dateOfAlloc),
      Cell: ({ cell }) => cell.getValue().toISOString().split('T')[0],
    },
    {
      id: 'classification',
      header: 'Classification',
      accessorFn: (row) => row.presentLead?.classification || 'N/A',
      size: 120
    },
    {
      id: 'imp',
      header: 'Important',
      accessorFn: (row) => row.presentLead?.imp ?? false,
      Cell: ({ cell }) => (
        <Box sx={{ 
          display: 'flex', 
          color: cell.getValue() ? '#4CAF50' : '#8797A8',
          fontSize: '0.7rem',
          fontWeight: 'bold',
          fontStyle: 'italic'
        }}>
          {cell.getValue() ? 'Yes' : 'No'}
        </Box>
      ),
      size: 80,
    },
    {
      id: 'user',
      header: 'Allocated To',
      size: 150,
      accessorFn: (row) => row.user ? `${row.user.firstName} ${row.user.lastName}` : 'N/A',
    },
  ], []);

  // Modified data fetching
  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {});

    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    getAllocatedByMeleads({
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
    })
      .then(response => {
        if (response && response.data) {
          setDomains(response.data.content);
          setTotalRowCount(response.data.totalElements);
        }
      })
      .catch(error => {
        console.error('API call failed:', error);
        setDomains([]);
        setTotalRowCount(0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [columnFilters, sorting, pagination.pageIndex, pagination.pageSize, globalSearch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Simplified table configuration
  const domainTable = useMaterialReactTable({
    columns,
    data: domains,
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: { tableLayout: 'fixed' }
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0'
      }
    },
    muiTableContainerProps: {
      sx: { maxHeight: '600px' }
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      }
    },

    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => (
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        <Button
          variant="outlined"
          size="small"
          startIcon={<FilterListIcon />}
          onClick={() => setOpenFilterDialog(true)}
          sx={commonButtonStyle}
        >
          Filter
        </Button>

        <Button
          variant="outlined"
          size="small"
          startIcon={<SortIcon />}
          onClick={() => setOpenSortDialog(true)}
          sx={commonButtonStyle}
        >
          Sort
        </Button>

        <Button
          disabled={table.getSelectedRowModel().rows.length === 0}
          variant="outlined"
          startIcon={<PanToolAltOutlined />}
          onClick={() => {
            setOpenAllocate(true);
            let id = table.getSelectedRowModel().rows.map((row) => row.original.id);
            setIdds(id);
          }}
          sx={commonButtonStyle}
        >
          ReAllocate
        </Button>

        <Button
          disabled={table.getSelectedRowModel().rows.length === 0}
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={handleExport}
          sx={commonButtonStyle}
        >
          Export
        </Button>

        <Box flexGrow={1} />

        <TextField
          placeholder="Search domains..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          size="small"
          sx={searchFieldStyles}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon 
                  sx={{ 
                    fontSize: '1rem', 
                    color: '#8797A8',
                    cursor: 'pointer',
                    '&:hover': {
                      color: '#6b7a8a',
                    },
                  }}
                  onClick={handleSearch} 
                />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    ),

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading
    },
  });

  // Simplify handleAllocation to use a single API call
  const handleAllocation = () => {
    setLoading(true);
    const selectedUserEmail = selectedUser.email;
    
    reallocateLeadToProcessorP(idds, selectedUserEmail)
      .then(() => {
        setUpsuc1(true);
        fetchData();
        handleCloseAllocate(); 
        setIdds([]);
        domainTable.resetRowSelection();
      })
      .catch(() => {
        setUpfail1(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Update handleCloseAllocate
  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setSelectedUser(null);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = String(now.getFullYear());
    return `lead-contacts-${day}-${month}-${year}.csv`;
  };

  const handleExport = useCallback(() => {
    const selectedRows = domainTable.getSelectedRowModel().rows;
    
    const exportData = selectedRows.map(row => {
      const originalData = row.original;
      const topContact = originalData.leadWork?.topContacts?.[0] || {};
      
      return {
        domainName: originalData.presentLead?.domainName || '',
        leadName: originalData.leadName || '',
        userName: `${originalData.user?.firstName || ''} ${originalData.user?.lastName || ''}`.trim(),
        primaryEmail: topContact.primaryEmail || '',
        secondaryEmail: topContact.secondaryEmail || '',
        Important: originalData.presentLead?.imp || false
      };
    });

    // Convert to CSV
    const headers = ['domainName', 'leadName', 'userName', 'primaryEmail', 'secondaryEmail', 'Important'];
    const csvContent = [
      headers.join(','),
      ...exportData.map(row => 
        headers.map(header => 
          typeof row[header] === 'boolean' ? row[header].toString() : `"${row[header]}"`
        ).join(',')
      )
    ].join('\n');

    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', getCurrentDateTime());
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [domainTable]);

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2} alignItems='center' width='100%'>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Allocated By Me
          </Typography>
        </Stack>

        <Box>
          <MaterialReactTable table={domainTable} />
        </Box>

        {/* Snackbars */}
        <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
          <Alert severity="success" sx={{ width: '100%' }}>
            Moved to W-Station!
          </Alert>
        </Snackbar>
        <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
          <Alert severity="error" sx={{ width: '100%' }}>
            Not Moved to W-Station!
          </Alert>
        </Snackbar>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />

          <Dialog
            fullWidth
            open={openAllocate}
            onClose={handleCloseAllocate}
            onBackdropClick={handleCloseAllocate}
            PaperProps={{
              sx: {
                borderRadius: '8px',
                boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                overflow: 'hidden',
                maxWidth: '600px',
                margin: '16px'
              }
            }}
          >
            <DialogTitle
              sx={{
                pt: 2,
                px: 3,
                borderBottom: '1px solid #e0e0e0',
                backgroundColor: '#fff'
              }}
            >
              Allocate to Processor
            </DialogTitle>

            <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
              <DialogContentText sx={{
                fontSize: '0.875rem',
                color: '#666',
                mb: 2,
                mt: 1,
                fontWeight: 500
              }}>
                {idds.length} domain{idds.length !== 1 && 's'} selected
              </DialogContentText>

              <Box sx={{
                p: 3,
                backgroundColor: '#fff',
                borderRadius: '6px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
              }}>
                <FormControl fullWidth>
                  <InputLabel id="user-select-label" sx={{ color: '#666' }}>
                    Select Processor
                  </InputLabel>
                  <Select
                    labelId="user-select-label"
                    value={selectedUser || ''}
                    onChange={handleUserSelect}
                    input={<OutlinedInput label="Select Processor" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        <Chip
                          label={selected.email === user.email
                            ? 'Me'
                            : `${selected.firstName} ${selected.lastName}`
                          }
                          sx={{
                            backgroundColor: '#E3F2FD',
                            color: '#1976d2',
                            fontWeight: 500,
                            fontSize: '0.8rem'
                          }}
                        />
                      </Box>
                    )}
                  >
                    <MenuItem key={user.email} value={user}>
                      <ListItemText
                        primary="Me"
                        primaryTypographyProps={{
                          sx: {
                            fontSize: '0.875rem',
                            fontWeight: 500,
                            color: '#2f3542'
                          }
                        }}
                      />
                    </MenuItem>
                    {leadAgentsChildren.map((child) => (
                      <MenuItem key={child.email} value={child}>
                        <ListItemText
                          primary={`${child.firstName} ${child.lastName}`}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: '0.875rem',
                              fontWeight: 500,
                              color: '#2f3542'
                            }
                          }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </DialogContent>

            <DialogActions sx={{
              py: 2,
              px: 3,
              borderTop: '1px solid #e0e0e0',
              backgroundColor: '#fff'
            }}>
              <Button
                onClick={handleCloseAllocate}
                variant="outlined"
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  borderColor: '#1976d2',
                  color: '#1976d2',
                  '&:hover': {
                    borderColor: '#1976d2',
                    backgroundColor: 'rgba(25, 118, 210, 0.04)'
                  }
                }}
              >
                Cancel
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button
                onClick={handleAllocation}
                variant="contained"
                disabled={loading || !selectedUser}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  px: 3,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }}
              >
                Allocate
              </Button>
            </DialogActions>
          </Dialog>
        </LocalizationProvider>
      </Stack>
    </ThemeProvider>
  );
}