import { msalInstance } from "..";
import { appRoles } from "../msalConfig";


export function isDeveloper() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Developer);
}

export function isAdmin() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Admin) || isDeveloper();
}

export function isQA() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.QA) || isAdmin() || isDeveloper();
}

export function isManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Manager) || isAdmin() || isDeveloper();
}

export function isProcessor() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Employee) || isAdmin() || isDeveloper();
}

export function isSocialManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.SocialManager) || arr.includes(appRoles.Admin) || isDeveloper();
}

export function isSocialAgent() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.SocialAgent) || arr.includes(appRoles.SocialManager) || arr.includes(appRoles.Admin) || isDeveloper();
}

export function isLeadManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Admin) || arr.includes(appRoles.LeadManager) || isDeveloper();
}

export function isLeadEmailAgent() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Admin) || arr.includes(appRoles.LeadManager) || arr.includes(appRoles.LeadEmailAgent) || isDeveloper();
}

export function isESManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESManager) || isDeveloper() || isAdmin();
}

export function isESAnalyst() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESAnalyst) || isDeveloper() || isAdmin();
}

export function isEDManager() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.EDManager) || isDeveloper() || isAdmin();
}

export function isEDAnalyst() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.EDAnalyst) || isDeveloper() || isAdmin();
}


export function isltdExpiredProcessor() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Admin) || arr.includes(appRoles.LtdExpiredProcessor) || isDeveloper();
}



//////////////////////////////////////////////////Visibility rights Start//////////////////////////////

export function canAddOwned() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AddOwned) || arr.includes(appRoles.AddAll) || isDeveloper() || isAdmin();
}

export function canAddLtd() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AddLtd) || arr.includes(appRoles.AddAll) || isDeveloper() || isAdmin();
}

export function canAddPD() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AddPD) || arr.includes(appRoles.AddAll) || isDeveloper() || isAdmin();
}

export function canPlaceOrdersAq() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.PlaceOrdersAq) || isDeveloper() || isAdmin();
}

export function canAddAld() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AddAld) || arr.includes(appRoles.AddAll) || isDeveloper() || isAdmin();
}

export function canAddAll() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AddAll) || isDeveloper() || isAdmin();
}

export function canAllocateOwned() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AllocateOwned) || arr.includes(appRoles.AllocateAll) || isDeveloper() || isAdmin();
}

export function canAllocateAll() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AllocateAll) || isDeveloper() || isAdmin();
}

export function canAllocateLtd() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.AllocateLtd) || arr.includes(appRoles.AllocateAll) || isDeveloper() || isAdmin();
}

//////////////////////////////////////////////////Action rights Ended/////////////////////////////
////////////////////////////////////////////////Visibility rights Start//////////////////////////////

export function isProspecterVisible() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESManager) || arr.includes(appRoles.EDManager) || arr.includes(appRoles.EDAnalyst) || arr.includes(appRoles.ESAnalyst) || arr.includes(appRoles.Admin) || isDeveloper();
}

export function isAcquisitionCenterVisible() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESManager) || arr.includes(appRoles.EDManager) || arr.includes(appRoles.Admin) || isDeveloper();
}

export function isRenewalCenterVisible() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.Admin) || isDeveloper();
}

export function isEmailCenterVisible() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.ESManager) || arr.includes(appRoles.EDManager) || arr.includes(appRoles.TopLeadEmailAgent) || arr.includes(appRoles.Admin) || isDeveloper();
}

export function isLeadCenterVisible() {
    const arr = msalInstance.getActiveAccount().idTokenClaims.roles;
    return arr.includes(appRoles.LeadManager) || arr.includes(appRoles.LeadEmailAgent) || arr.includes(appRoles.Admin) || isDeveloper();
}

//////////////////////////////////////////////////Visibility rights ended//////////////////////////////


export function getUserName() {
    const arr = msalInstance.getActiveAccount().username;
    return arr;
}

export function getName() {
    const arr = msalInstance.getActiveAccount().name;
    return arr;
}

