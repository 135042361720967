import * as React from 'react';
import { Avatar, Box, Button, CssBaseline, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, ThemeProvider, Toolbar, Tooltip, Typography, styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { theme } from './Theme';
import { Archive, ArchiveOutlined, AssignmentLateOutlined, AssignmentOutlined, DashboardOutlined, DomainOutlined, GroupOutlined, Logout, NotificationsOutlined, PlaylistAddCheckOutlined, WorkOutline, ShoppingCartOutlined, Email, BusinessOutlined, FolderOutlined, SupervisorAccountOutlined, WorkHistoryOutlined, SearchOutlined, FactCheckOutlined, LeaderboardOutlined, LocationOnOutlined, ForwardToInboxOutlined, FolderSpecialOutlined, AnalyticsOutlined, AutorenewOutlined, AccessTimeOutlined, AccountBalanceOutlined } from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router';
import userimg from "./images/user1.jpg";

import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import WorkIcon from '@mui/icons-material/Work';
import { canAllocateAll, canAllocateLtd, canAllocateOwned, isAcquisitionCenterVisible, isEmailCenterVisible, isLeadCenterVisible, isRenewalCenterVisible, isAdmin, isManager, isltdExpiredProcessor, isProcessor, isDeveloper, isProspecterVisible, isQA, isSocialAgent, isESManager, isESAnalyst, canPlaceOrdersAq, isLeadManager, isLeadEmailAgent } from './msalService';

import { getToken } from './axiosInstance';
import { useUser } from './UserProvider';
import { syncUser } from './api';
import nk from "./images/nk.png";

const drawerWidth = 180;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,

  }),
  ...(open && {
    marginLeft: 0,//drawerWidth,
    width: '100%',// `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Home() {
  const navigate = useNavigate();
  const [olevel1, setOlevel1] = React.useState(false);
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { user, updateUser, olevel } = useUser();
  const [open, setOpen] = React.useState(true);

  const [domainStudioOpen, setDomainStudioOpen] = React.useState(false);
  const [acquisitionsOpen, setAcquisitionsOpen] = React.useState(false);
  const [reviewOpen, setReviewOpen] = React.useState(false);
  const [processingCenterOpen, setProcessingCenterOpen] = React.useState(false);
  const [researchHubOpen, setResearchHubOpen] = React.useState(false);
  const [allocationCenterOpen, setAllocationCenterOpen] = React.useState(false);
  const [userLocationOpen, setUserLocationOpen] = React.useState(false);
  const [portfolioOpen, setPortfolioOpen] = React.useState(false);
  const [ltdStudioOpen, setLtdStudioOpen] = React.useState(false);
  const [leadCenterOpen, setLeadCenterOpen] = React.useState(false);
  const [workStationOpen, setWorkStationOpen] = React.useState(false);
  const [prospectorOpen, setProspectorOpen] = React.useState(false);
  const [acquisitionCenterOpen, setAcquisitionCenterOpen] = React.useState(false);
  const [renewalCenterOpen, setRenewalCenterOpen] = React.useState(false);
  const [emailCenterOpen, setEmailCenterOpen] = React.useState(false);


  const handleDrawerToggle = () => {
    const newOpenState = !open;
    setOpen(newOpenState);
    // Close all nested menus when closing the drawer
    if (!newOpenState) {
      setDomainStudioOpen(false);
      setAcquisitionsOpen(false);
      setReviewOpen(false);
      setProcessingCenterOpen(false);
      setResearchHubOpen(false);
      setAllocationCenterOpen(false);
      setUserLocationOpen(false);
      setPortfolioOpen(false);
      setLtdStudioOpen(false);
      setLeadCenterOpen(false);
      setWorkStationOpen(false);
      setProspectorOpen(false);
      setAcquisitionCenterOpen(false);
      setRenewalCenterOpen(false);
      setEmailCenterOpen(false);
    }
  };

  const handleNestedMenu = (event, setStateFunction, currentState) => {
    if (!open) { setOpen(true) }
    setStateFunction(!currentState);
  };

  React.useEffect(() => {
    syncUser().then((res) => {
      console.log(res.data); updateUser(res.data); if (res.data.users.length > 0)
        setOlevel1(false);
      else setOlevel1(true);
    }).catch((err) => { console.log(err); });
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ backgroundColor: '#fcfcfa', height: '100%', minHeight: '100vh' }}>
        <Stack direction="column" >
          <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" elevation={0} open={open} sx={{
              backgroundColor: '#738e97',//'white' 
              display: 'flex', width: '100%', justifyContent: 'center'

            }}
            >
              <Toolbar variant='dense' disableGutters
                sx={{ paddingLeft: '16px', paddingRight: '30px' }}
              >
                <Stack direction='row' alignItems='center' width='100%' justifyContent={open ? 'space-between' : 'center'}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={handleDrawerToggle}
                  >
                    <img
                      src={nk}
                      alt="placeholder"
                      style={{
                        borderRadius: '0',
                        transform: 'rotate(0deg)',
                        opacity: '1',
                        height: '24px',
                        padding: 0,
                        margin: 0
                      }}
                    />
                    {open && (
                      <Typography align='center' fontSize={18} fontWeight={500} fontFamily='Overpass' color='white' marginLeft={1}>
                        NAMEKART
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ flexGrow: 1 }}></Box>
                  <Tooltip title='Will be available soon :)'>
                    <Button
                      variant="text"
                      startIcon={<NotificationsOutlined sx={{ width: 24, height: 24 }} />}
                      sx={{ height: 24, color: 'white', textTransform: 'none' }}
                    >
                      Notifications
                    </Button>
                  </Tooltip>
                  <Button
                    variant="text"
                    endIcon={<Avatar alt="User" src={userimg} sx={{ width: 24, height: 24, marginLeft: 1 }} />}
                    sx={{ color: 'white', textTransform: 'none', height: 24 }}
                  >
                    Howdy, {user.firstName}
                  </Button>
                  {isAuthenticated && <Tooltip title='Log Out'><IconButton sx={{
                    color: 'white'
                    , marginLeft: 1
                  }} onClick={() => {
                    if (accounts.length > 0) {
                      const account = accounts[0];
                      instance.logoutRedirect({ account });
                    }
                  }}><Logout sx={{ width: 24, height: 24 }} /></IconButton></Tooltip>}
                </Stack>
              </Toolbar>
            </AppBar>
          </Box>
          <Drawer PaperProps={{
            sx: {
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '6px',
              }, background: '#738e97',
              color: '#cac4d0', marginTop: '32px'
            }
          }} variant="permanent" open={open}>
            <List sx={{
              width: '100%', color: 'white'
              , fontSize: 14, fontFamily: 'Roboto', fontWeight: '500', lineHeight: 20, letterSpacing: 0.10, wordWrap: 'break-word', paddingBottom: 4,
              '& .MuiListItemText-primary': {
                fontSize: 14,
              },
              '& .MuiListItemText-root': {
                marginLeft: 1.5,
                opacity: open ? 1 : 0
              },
              '& .MuiListItemIcon-root': {
                minWidth: open ? 36 : 48,
                justifyContent: open ? 'flex-start' : 'center'
              },
            }}  >
              <ListItem button onClick={() => navigate("/")} sx={{ justifyContent: open ? 'flex-start' : 'center' }}>
                <Tooltip title="Dashboard" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0', padding: 0, margin: 0 }} >
                    <DashboardOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Dashboard" primaryTypographyProps={{ fontSize: 14 }} />
              </ListItem>


              <ListItem onClick={() => { navigate("/pqcentre") }} button sx={{ justifyContent: open ? 'flex-start' : 'center' }}>
                <Tooltip title="PQ Center" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <AssignmentLateOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="PQ Center" />
              </ListItem>


              {(isAdmin() || isManager() || isltdExpiredProcessor()) &&
                <ListItem button onClick={(e) => handleNestedMenu(e, setLtdStudioOpen, ltdStudioOpen)}
                  sx={{ justifyContent: open ? 'flex-start' : 'center' }}>
                  <Tooltip title="LTD Studio" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <AccessTimeOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="LTD Studio" />
                </ListItem>}
              {open && ltdStudioOpen &&
                <List sx={{ paddingLeft: 3 }}>
                  {(isAdmin() || isManager()) && (<>
                    <ListItem button onClick={() => { navigate("/ltdstudio/active") }}>
                      <ListItemText primary="Active" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/ltdstudio/closed") }}>
                      <ListItemText primary="Closed" />
                    </ListItem>
                  </>)}
                  {(isAdmin() || isltdExpiredProcessor()) && (
                    <ListItem button onClick={() => { navigate("/ltdstudio/removed") }}>
                      <ListItemText primary="Removed" />
                    </ListItem>
                  )}
                  {(isAdmin() || isManager()) && (
                    <ListItem button onClick={() => { navigate("/ltdstudio/archive") }}>
                      <ListItemText primary="Archive" />
                    </ListItem>
                  )}
                  {(isAdmin() || isManager()) && (
                    <ListItem button onClick={() => { navigate("/ltdstudio/deleted") }}>
                      <ListItemText primary="Deleted" />
                    </ListItem>
                  )}                 
                </List>
              }


              {(isAdmin() || isManager()) &&
                <ListItem button onClick={(e) => handleNestedMenu(e, setPortfolioOpen, portfolioOpen)}>
                  <Tooltip title="Portfolio" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <AccountBalanceOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Portfolio" />
                </ListItem>
              }
              {open && portfolioOpen &&
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => { navigate("/portfolio/new") }}>
                    <ListItemText primary="New" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/portfolio/active") }}>
                    <ListItemText primary="Active" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/portfolio/expiring") }}>
                    <ListItemText primary="Expiring" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/portfolio/expired") }}>
                    <ListItemText primary="Expired" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/portfolio/deleted") }}>
                    <ListItemText primary="Deleted" />
                  </ListItem>
                </List>
              }


              <ListItem button onClick={(e) => handleNestedMenu(e, setAllocationCenterOpen, allocationCenterOpen)}>
                <Tooltip title="Allocation Center" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <FolderOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Allocation Center" />
              </ListItem>
              {open && allocationCenterOpen &&
                <List sx={{ paddingLeft: 3 }}>
                  {(canAllocateAll() || isAdmin()) && <ListItem button onClick={() => navigate("/bulkallocator")}>
                    <ListItemText primary="Bulk Allocator" />
                  </ListItem>}
                  {(isProcessor() || isAdmin()) && <ListItem button onClick={() => navigate("/mydomains")}>
                    <ListItemText primary="My Domains" />
                  </ListItem>}
                  {((isProcessor() || isAdmin()) && !olevel1) && <>
                    <ListItem button onClick={() => navigate("/allocatedbyme")}>
                      <ListItemText primary="Allocated by me" />
                    </ListItem>
                    <ListItem button onClick={() => navigate("/nonallocated")}>
                      <ListItemText primary="Non-Allocated" />
                    </ListItem>
                  </>}
                </List>}


              {isRenewalCenterVisible() &&
                <ListItem button onClick={(e) => handleNestedMenu(e, setRenewalCenterOpen, renewalCenterOpen)}>
                  <Tooltip title="Renewal Center" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <AutorenewOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Renewal Center" />
                </ListItem>
              }
              {open && renewalCenterOpen && (
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => navigate("/renewalcenter/recohub")}>
                    <ListItemText primary="Reco Hub" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/renewalcenter/decisionhub")}>
                    <ListItemText primary="Decision Hub" />
                  </ListItem>
                  <ListItem button disabled={!canPlaceOrdersAq()} onClick={() => navigate("/renewalcenter/ordershub")}>
                    <ListItemText primary="Orders Hub" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/renewalcenter/archive")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                </List>
              )}


              {isLeadCenterVisible() && <>
                <ListItem button onClick={(e) => handleNestedMenu(e, setLeadCenterOpen, leadCenterOpen)}>
                  <Tooltip title="Lead Center" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <LeaderboardOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Lead Center" />
                </ListItem>
                {open && leadCenterOpen &&
                  <List sx={{ paddingLeft: 3 }}>
                    <ListItem button onClick={() => navigate("/myleads")}>
                      <ListItemText primary="My Leads" />
                    </ListItem>
                    {isLeadManager() && <>
                      <ListItem button onClick={() => navigate("/allocatedbymeleads")}>
                        <ListItemText primary="Allocated by me" />
                      </ListItem>
                      <ListItem button onClick={() => navigate("/nonallocatedleads")}>
                        <ListItemText primary="Non Allocated" />
                      </ListItem>
                    </>}
                    <ListItem button onClick={() => navigate("/leadarchive")}>
                      <ListItemText primary="Archive" />
                    </ListItem>
                  </List>
                }
              </>}


              {isAdmin() && <>
                <ListItem button onClick={(e) => handleNestedMenu(e, setAcquisitionsOpen, acquisitionsOpen)} disabled>
                  <Tooltip title="Acquisitions" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <AssignmentOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Acquisitions" />
                </ListItem>
              </>}


              <ListItem button onClick={(e) => handleNestedMenu(e, setReviewOpen, reviewOpen)}>
                <Tooltip title="Review" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <PlaylistAddCheckOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Review" />
              </ListItem>
              {reviewOpen &&
                <List sx={{ paddingLeft: 3 }}>
                  {(isQA() || isAdmin()) && <>
                    <ListItem button onClick={() => { navigate("/analysework/0") }}>
                      <ListItemText primary="Analyse Work" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate("/analysedreports") }}>
                      <ListItemText primary="Analysed Reports" />
                    </ListItem>
                  </>}
                  {(isAdmin() || isProcessor()) && <>
                    <ListItem button onClick={() => navigate("/qareports")}>
                      <ListItemText primary="QA Reports" />
                    </ListItem>
                    <ListItem button disabled>
                      <ListItemText primary="Approvals" />
                    </ListItem>
                  </>}
                  {(isAdmin() || isProcessor() || isQA()) &&
                    <ListItem button onClick={() => navigate("/misreports")}>
                      <ListItemText primary="MIS Reports" />
                    </ListItem>
                  }
                </List>
              }


              <ListItem button onClick={(e) => handleNestedMenu(e, setProcessingCenterOpen, processingCenterOpen)}>
                <Tooltip title="Processing Center" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <FactCheckOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Processing Center" />
              </ListItem>
              {processingCenterOpen &&
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => { navigate("/domainlive") }}>
                    <ListItemText primary="Processing Live" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/workarchives") }}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                  <ListItem button>
                    <ListItemText primary="Special Tasks" />
                  </ListItem>
                </List>
              }


              {/* {(isProcessor() || isAdmin()) && <>
                <ListItem button onClick={() => navigate('/workinput/0')}>
                  <Tooltip title="Old-Work Station" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <WorkOutline />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Old-Work Station" />
                </ListItem>
              </>} */}


              {(isDeveloper() || isProcessor() || isAdmin() || isLeadCenterVisible()) && <>
                <ListItem button onClick={(e) => handleNestedMenu(e, setWorkStationOpen, workStationOpen)}>
                  <Tooltip title="Work Station" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <WorkHistoryOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Work Station" />
                </ListItem>
                {workStationOpen &&
                  <List sx={{ paddingLeft: 3 }}>
                    {isLeadManager() &&
                      <ListItem button onClick={() => navigate("/workstation/lead")}>
                        <ListItemText primary="L-Work Station" />
                      </ListItem>}

                    {isLeadEmailAgent() &&
                      <ListItem button onClick={() => navigate("/workstation/email")}>
                        <ListItemText primary="E-Work Station" />
                      </ListItem>}

                    {isProcessor() &&
                      <ListItem button onClick={() => navigate("/workstation/full")}>
                        <ListItemText primary="F-Work Station" />
                      </ListItem>}
                  </List>}
              </>}



              {isAdmin() && <ListItem button onClick={() => navigate('/workforce')} sx={{ justifyContent: open ? 'flex-start' : 'center' }}>
                <Tooltip title="WorkForce" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <GroupOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="WorkForce" />
              </ListItem>}


              {isAdmin() && <>
                <ListItem button onClick={(e) => handleNestedMenu(e, setUserLocationOpen, userLocationOpen)}>
                  <Tooltip title="User Location" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <LocationOnOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="User Location" />
                </ListItem></>}
              {userLocationOpen && <List sx={{ paddingLeft: 3 }}
              >
                <ListItem button onClick={() => { navigate("/userlocation") }}>
                  <ListItemText primary="Location Logs" />
                </ListItem>
                <ListItem button onClick={() => { navigate("userlastlocation") }}>
                  <ListItemText primary="Last Location" />
                </ListItem>
              </List>}



              {isSocialAgent() && <>
                <ListItem button onClick={(e) => handleNestedMenu(e, setResearchHubOpen, researchHubOpen)}>
                  <Tooltip title="Research Hub" placement="right" arrow>
                    <ListItemIcon sx={{ color: '#cac4d0' }}>
                      <SearchOutlined />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Research Hub" />
                </ListItem></>}
              {researchHubOpen &&
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => { navigate("/researchcenter/claimhub") }}>
                    <ListItemText primary="Claim Hub" />
                  </ListItem>
                  <ListItem button onClick={() => { navigate("/researchcenter/myclaims") }}>
                    <ListItemText primary="My Claims" />
                  </ListItem>
                  {<ListItem button onClick={() => { navigate("/researchcenter/leadshub") }
                  }>
                    <ListItemText primary="Leads Hub" />
                  </ListItem>}
                  {<><ListItem button onClick={() => navigate("/researchcenter/archive")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                  </>}
                  {<><ListItem button onClick={() => navigate("/researchcenter/error")}>
                    <ListItemText primary="Error" />
                  </ListItem>
                  </>}
                </List>}




              {isProspecterVisible() && <ListItem button onClick={(e) => handleNestedMenu(e, setProspectorOpen, prospectorOpen)}>
                <Tooltip title="Prospector" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <AnalyticsOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Prospector" />
              </ListItem>}
              {prospectorOpen && (
                <List sx={{ paddingLeft: 2 }}>
                  {isAdmin() && (
                    <ListItem button onClick={() => navigate("/prospector/masterdata")}>
                      <ListItemText primary="Master Data" />
                    </ListItem>
                  )}
                  {(isAdmin() || isESManager() || isESAnalyst()) && (
                    <ListItem button onClick={() => navigate("/prospector/analysishub")}>
                      <ListItemText primary="Analysis Hub" />
                    </ListItem>
                  )}
                  <ListItem button onClick={() => navigate("/prospector/shortlisteddomains")}>
                    <ListItemText primary="Shortlist" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/prospector/archive")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                </List>
              )}




              {isAcquisitionCenterVisible() && <ListItem button onClick={(e) => handleNestedMenu(e, setAcquisitionCenterOpen, acquisitionCenterOpen)}>
                <Tooltip title="Acquisition Center" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <ShoppingCartOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Acquisition Center" />
              </ListItem>}
              {acquisitionCenterOpen && (
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/recohub")}>
                    <ListItemText primary="Reco Hub" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/decisionhub")}>
                    <ListItemText primary="Decision Hub" />
                  </ListItem>
                  <ListItem button disabled={!canPlaceOrdersAq()} onClick={() => navigate("/acquisitioncenter/ordershub")}>
                    <ListItemText primary="Orders Hub" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/active")}>
                    <ListItemText primary="Active" />
                  </ListItem>
                  <ListItem button onClick={() => navigate("/acquisitioncenter/archive")}>
                    <ListItemText primary="Archive" />
                  </ListItem>
                </List>
              )}



              {isEmailCenterVisible() && <ListItem button onClick={(e) => handleNestedMenu(e, setEmailCenterOpen, emailCenterOpen)}>
                <Tooltip title="Email Center" placement="right" arrow>
                  <ListItemIcon sx={{ color: '#cac4d0' }}>
                    <ForwardToInboxOutlined />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Email Center" />
              </ListItem>}
              {emailCenterOpen && (
                <List sx={{ paddingLeft: 3 }}>
                  <ListItem button onClick={() => navigate("/emailcenter/emailhub")}>
                    <ListItemText primary="Email Hub" />
                  </ListItem>
                </List>
              )}


            </List>
          </Drawer>
        </Stack>
        <Box height='100%' sx={{
          marginLeft: open ? `${drawerWidth}px` : `calc(${theme.spacing(8)} + 1px)`,
          paddingLeft: '30px',
          paddingRight: '38px',
          paddingTop: '62px',
          paddingBottom: '80px',
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }}>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  )
}
