import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { getAllSocialEmployees, getSocialChildren, getResearchArchiveData, getUserNTeams } from '../api'; 
import {
  Box,
  Button,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Tooltip,
  Link,
  Badge,
  Snackbar,
  Alert,
  ThemeProvider
} from '@mui/material';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Sort as SortIcon } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { FileDownloadOutlined } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';

// Components
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import { theme } from '../Theme';

// Get current year for copyright
const currentYear = new Date().getFullYear();

// Add these styles
const commonButtonStyles = {
  width: '28px',
  height: '28px',
  color: '#8797A8',
  backgroundColor: '#ffffff',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#8797A8',
  },
  '&.Mui-disabled': {
    backgroundColor: '#f5f5f5',
    borderColor: '#e0e0e0',
    color: '#bdbdbd',
  },
};

const searchFieldStyles = {
  width: '160px',
  '& .MuiOutlinedInput-root': {
    height: '28px',
    fontSize: '0.875rem',
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    '& fieldset': {
      borderColor: '#e0e0e0',
      borderRadius: '12px',
    },
    '&:hover fieldset': {
      borderColor: '#8797A8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#8797A8',
    },
  },
};

const Error = () => {
  // State management
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'dateOfAlloc',
      header: 'allocdate',
      size: 70,
      accessorFn: (row) => {
        const date = row.dateOfAlloc ? new Date(row.dateOfAlloc) : null;
        return date;
      },
      Cell: ({ cell }) => {
        const date = cell.getValue();
        if (!date) return '-';
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    {
      accessorKey: 'deadline',
      header: 'deadline',
      size: 60,
      accessorFn: (row) => {
        const date = row.presentDomain?.deadline ? new Date(row.presentDomain.deadline) : null;
        return date;
      },
      Cell: ({ cell }) => {
        const date = cell.getValue();
        if (!date) return '-';
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    {
      accessorKey: 'edate',
      header: 'edate',
      size: 60,
      accessorFn: (row) => {
        const date = row.presentDomain?.edate ? new Date(row.presentDomain.edate) : null;
        return date;
      },
      Cell: ({ cell }) => {
        const date = cell.getValue();
        if (!date) return '-';
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    {
      accessorKey: 'domainName',
      header: 'domain',
      size: 120,
      Cell: ({ cell }) => (
        <Tooltip title={cell.getValue()} placement="bottom">
          <Typography variant="caption" sx={{ fontSize: '0.8rem' }}>
            {cell.getValue()}
          </Typography>
        </Tooltip>
      )
    },
    {
      accessorKey: 'list',
      header: 'list',
      size: 80,
      accessorFn: (row) => row.presentDomain?.list || '-'
    },
    {
      accessorKey: 'pStatus',
      header: 'p-status',
      size: 80,
    },
    {
      accessorKey: 'dateOfWork',
      header: 'wdate',
      size: 60,
      accessorFn: (row) => {
        const date = row.socialWork?.dateOfWork ? new Date(row.socialWork.dateOfWork) : null;
        return date;
      },
      Cell: ({ cell }) => {
        const date = cell.getValue();
        if (!date) return '-';
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    {
      accessorKey: 'dateOfUpload',
      header: 'adate',
      size: 60,
      accessorFn: (row) => {
        const date = row.presentDomain?.dateOfUpload ? new Date(row.presentDomain.dateOfUpload) : null;
        return date;
      },
      Cell: ({ cell }) => {
        const date = cell.getValue();
        if (!date) return '-';
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' }).replace(/ /g, '-');
      }
    },
    {
      accessorKey: 'user',
      header: 'processor',
      size: 100,
      accessorFn: (row) => `${row.user?.firstName || ''} ${row.user?.lastName || ''}`.trim() || '-'
    },
    {
      accessorKey: 'allocator',
      header: 'allocator',
      size: 100,
      accessorFn: (row) => `${row.allocator?.firstName || ''} ${row.allocator?.lastName || ''}`.trim() || '-'
    },
    {
      accessorKey: 'noLeadsAvailable',
      header: 'nl',
      size: 40,
      accessorFn: (row) => row.socialWork?.noLeadsAvailable,
      Cell: ({ row }) => {
        if (!row.original.socialWork) return '-';
        const value = row.original.socialWork.noLeadsAvailable;
        return value ? 'yes' : 'no';
      }
    },
    {
      accessorKey: 'leads',
      header: 'leads',
      size: 130,
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: '#FFC0CB',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '0.7rem',
          padding: '1px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontStyle: 'italic',
          paddingLeft: '5px',
          '& .MuiTableSortLabel-icon': {
            display: 'none',
          },
          '& .Mui-active .MuiTableSortLabel-icon': {
            display: 'none',
          },
        },
      },
      Cell: ({ row }) => {
        const socialWork = row.original.socialWork;
        if (!socialWork?.topLeads?.length) return '-';

        return (
          <Box>
            {socialWork.topLeads.map((lead, index) => {
              let domain;
              if (lead.domain) {
                try {
                  domain = new URL(lead.domain).hostname;
                  domain = domain.replace('www.', '');
                } catch (error) {
                  domain = lead.domain;
                }
              } else {
                domain = '-';
              }

              let snapshotUrl = '';
              if (lead.domainSnapShotUrl) {
                try {
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(lead.domainSnapShotUrl, "text/xml");
                  snapshotUrl = xmlDoc.getElementsByTagName("snapshot")[0]?.textContent || '';
                } catch (error) {
                  console.error('Error parsing snapshot URL:', error);
                }
              }

              const isActive = lead.leadStatus?.toLowerCase() === 'active';

              return (
                <Tooltip
                  key={index}
                  title={
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 1,
                      maxWidth: '300px'
                    }}>
                      {snapshotUrl && (
                        <img
                          src={snapshotUrl}
                          alt="Domain Snapshot"
                          style={{
                            width: '100%',
                            height: 'auto',
                            maxHeight: '200px',
                            objectFit: 'contain',
                            filter: !isActive ? 'grayscale(100%)' : 'none'
                          }}
                        />
                      )}
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 'medium',
                          wordBreak: 'break-all'
                        }}
                      >
                        {lead.domain || '-'}
                      </Typography>
                    </Box>
                  }
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'common.white',
                        color: 'common.black',
                        border: '1px solid #ccc',
                        '& .MuiTooltip-arrow': {
                          color: 'common.white',
                        },
                        p: 1
                      }
                    }
                  }}
                >
                  <Box sx={{
                    width: '120px',
                    minHeight: '20px',
                    overflow: 'visible'
                  }}>
                    <Link
                      href={lead.domain.startsWith('http') ? lead.domain : `https://${lead.domain}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        display: 'block',
                        color: isActive ? 'primary.main' : 'text.secondary',
                        fontSize: '0.8rem',
                        textDecoration: 'none',
                        whiteSpace: 'normal',
                        overflow: 'visible',
                        wordBreak: 'break-word',
                        wordWrap: 'break-word',
                        lineHeight: '1.2',
                        maxWidth: '100%',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {domain}
                    </Link>
                  </Box>
                </Tooltip>
              );
            })}
          </Box>
        );
      }
    },
    {
      accessorKey: 'socials',
      header: 'socials',
      size: 130,
      Cell: ({ row }) => {
        const socialWork = row.original.socialWork;
        if (!socialWork?.topLeads?.length) return '-';

        const getUserId = (url) => {
          if (!url) return 'N/A';
          try {
            const urlObj = new URL(url);
            switch (true) {
              case url.includes('linkedin.com'):
                return urlObj.pathname.split('/')[2] || url;
              case url.includes('twitter.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              case url.includes('facebook.com'):
                return urlObj.pathname.split('/')[1] || url;
              case url.includes('instagram.com'):
                return '@' + (urlObj.pathname.split('/')[1] || url);
              case url.includes('youtube.com'):
                return urlObj.pathname.split('/')[1] || url;
              default:
                return url;
            }
          } catch {
            return url || 'N/A';
          }
        };

        const formatCount = (count) => {
          if (count === null || count === undefined) return '    ';
          if (count === 0) return '0'.padStart(4, ' ');
          if (count < 100) return count.toString().padStart(4, ' ');
          if (count >= 1000000) {
            const roundedMillions = Math.round(count / 1000000);
            return `${roundedMillions}M`;
          }
          if (count >= 1000) {
            const roundedThousands = Math.round(count / 1000);
            return `${roundedThousands}K`;
          }
          return Math.round(count / 100) * 100;
        };

        return (
          <Box>
            {socialWork.topLeads.map((lead, index) => (
              <Box key={index} sx={{ mb: 0.5, '&:last-child': { mb: 0 } }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Tooltip title={`Copyright: ${lead.copyright || 'N/A'}`}>
                    <CopyrightIcon sx={{
                      fontSize: '0.9rem',
                      color: lead.copyright
                        ? (Number(lead.copyright) < currentYear ? 'action.disabled' : 'primary.main')
                        : '#FFB6C1',
                    }} />
                  </Tooltip>
                  {lead.socials?.map((social, sIndex) => {
                    const platform = social.platform.toLowerCase();
                    let Icon;
                    let count;

                    switch (platform) {
                      case 'linkedin':
                        Icon = LinkedInIcon;
                        count = social.employees;
                        break;
                      case 'x':
                      case 'twitter':
                        Icon = XIcon;
                        count = social.followers;
                        break;
                      case 'facebook':
                        Icon = FacebookIcon;
                        count = social.followers;
                        break;
                      case 'instagram':
                        Icon = InstagramIcon;
                        count = social.followers;
                        break;
                      case 'youtube':
                        Icon = YouTubeIcon;
                        count = social.followers;
                        break;
                      default:
                        return null;
                    }

                    const isActive = social.status?.toLowerCase() === 'active';
                    const tooltipContent = `${getUserId(social.url)}${social.userFullName ? ` - ${social.userFullName}` : ''}`;

                    return (
                      <Tooltip key={sIndex} title={tooltipContent}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {social.url ? (
                            <Link href={social.url} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                              <Icon sx={{ fontSize: '0.9rem', color: isActive ? 'primary.main' : 'action.disabled' }} />
                              <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', color: 'primary.main', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                                {formatCount(count)}
                              </Typography>
                            </Link>
                          ) : (
                            <>
                              <Icon sx={{ fontSize: '0.9rem', color: isActive ? 'primary.main' : 'action.disabled' }} />
                              <Typography variant="caption" sx={{ ml: 0.2, fontSize: '0.7rem', lineHeight: '1', fontStyle: 'italic', width: '26px', textAlign: 'left' }}>
                                {formatCount(count)}
                              </Typography>
                            </>
                          )}
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </Box>
        );
      }
    }
  ], []);

  const handleExportData = useCallback((selectedRows) => {
    try {
      // Get all column accessorKeys from the columns definition, excluding leads and socials
      const exportColumns = columns
        .filter(col => col.accessorKey && !['leads', 'socials'].includes(col.accessorKey))
        .map(col => ({
          key: col.accessorKey,
          accessorFn: col.accessorFn
        }));

      // Create CSV header
      const header = exportColumns.map(col => col.key).join(',') + '\n';

      // Create CSV content
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          let value;
          if (col.accessorFn) {
            // Use accessorFn if defined
            value = col.accessorFn(row.original);
            // Format dates consistently with table display (DD-MMM)
            if (value instanceof Date) {
              value = value.toLocaleDateString('en-GB', { 
                day: '2-digit', 
                month: 'short'
              }).replace(/ /g, '-');
            }
          } else {
            value = row.original[col.key];
          }

          // Handle null/undefined values
          if (value === null || value === undefined) return '';
          // Escape strings containing commas
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      const csv = header + csvContent;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `error_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setSnackbar({
        open: true,
        message: 'Data exported successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export data',
        severity: 'error'
      });
    }
  }, [columns]);

  const getActiveFiltersCount = (filters) => filters.length;
  const getActiveSortsCount = (sorting) => sorting.length;

  const handleCopyDomains = (selectedRows) => {
    try {
      const domains = selectedRows.map(row => row.original.domainName).join('\n');
      navigator.clipboard.writeText(domains);
      setSnackbar({
        open: true,
        message: 'Domains copied to clipboard',
        severity: 'success'
      });
    } catch (error) {
      console.error('Copy error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to copy domains',
        severity: 'error'
      });
    }
  };

  const defaultToolbarActions = ({ table }) => {
    const selectedRows = table.getSelectedRowModel().rows;
    const activeFiltersCount = getActiveFiltersCount(columnFilters);
    const activeSortsCount = getActiveSortsCount(sorting);

    return (
      <Stack direction="row" spacing={1} width="100%" alignItems="center">
        <Box flexGrow={1} />
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            p: '4px',
            gap: '8px'
          }}
        >
          <TextField
            placeholder="Search Domain.."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            size="small"
            sx={searchFieldStyles}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ 
                      fontSize: '1rem', 
                      color: '#8797A8',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '#6b7a8a',
                      },
                    }}
                    onClick={handleSearch}
                  />
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Tooltip title={`Sort (${activeSortsCount} active)`} arrow placement="top">
              <Badge 
                badgeContent={activeSortsCount} 
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    fontSize: '0.5rem',
                    height: '12px',
                    minWidth: '12px',
                    padding: '0 3px'
                  }
                }}
              >
                <IconButton
                  onClick={() => setOpenSortDialog(true)}
                  size="small"
                  sx={commonButtonStyles}
                >
                  <SortIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Badge>
            </Tooltip>

            <Tooltip title={`Filter (${activeFiltersCount} active)`} arrow placement="top">
              <Badge 
                badgeContent={activeFiltersCount} 
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    fontSize: '0.5rem',
                    height: '12px',
                    minWidth: '12px',
                    padding: '0 3px'
                  }
                }}
              >
                <IconButton
                  onClick={() => setOpenFilterDialog(true)}
                  size="small"
                  sx={commonButtonStyles}
                >
                  <FilterListIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Badge>
            </Tooltip>

            <Tooltip title="Copy Domains" arrow placement="top">
              <span>
                <IconButton
                  onClick={() => handleCopyDomains(selectedRows)}
                  disabled={selectedRows.length === 0}
                  size="small"
                  sx={commonButtonStyles}
                >
                  <ContentCopyOutlinedIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title="Export Data" arrow placement="top">
              <span>
                <IconButton
                  onClick={() => handleExportData(selectedRows)}
                  disabled={selectedRows.length === 0}
                  size="small"
                  sx={commonButtonStyles}
                >
                  <FileDownloadOutlined sx={{ fontSize: '1rem' }} />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
    );
  };

  const columnVisibility = {
    dateOfUpload: false,
    edate: false,
    noLeadsAvailable: false
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading,
      globalFilter: globalSearch,
    },
    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 },
      sorting: [],
      columnVisibility
    },
    renderTopToolbarCustomActions: defaultToolbarActions,
    muiTableProps: {
      sx: { tableLayout: 'fixed' },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: { maxHeight: '600px' },
    },
    muiTableHeadCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1.2rem',
          },
        },
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        '&:first-of-type': {
          width: '30px',
        },
        '& .MuiCheckbox-root': {
          padding: '2px',
          '& svg': {
            fontSize: '1rem',
          },
        },
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Transform filters into the expected format and always include reviewStatus
      const transformedFilters = {
        // Always include the permanent reviewStatus filter
        reviewStatus: {
          operator: 'equals',
          value: 1,
          type: 'number'
        },
        // Add any other user-defined filters
        ...columnFilters.reduce((acc, filter) => {
          if (filter.id && filter.operator) {
            const filterId = filter.id === 'pStatus' ? 'status' : filter.id;
            acc[filterId] = {
              operator: filter.operator,
              value: filter.value,
              value2: filter.value2,
              type: filter.type
            };
          }
          return acc;
        }, {})
      };

      // Transform sorting into the expected format
      const transformedSorting = sorting.reduce((acc, sort) => {
        const sortId = sort.id === 'pStatus' ? 'status' : sort.id;
        acc[sortId] = sort.desc ? 'desc' : 'asc';
        return acc;
      }, {});

      const response = await getResearchArchiveData({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        sort: JSON.stringify(transformedSorting),
        filter: JSON.stringify(transformedFilters),
        search: globalSearch
      });

      if (response && response.data) {
        // Transform the response data to match the table structure
        const transformedData = response.data.content.map(item => ({
          id: item.id,
          past: item.past,
          type: item.type,
          domainName: item.domainName,
          dateOfAlloc: item.dateOfAlloc,
          dateOfUnalloc: item.dateOfUnalloc,
          pStatus: item.status,
          user: item.user ? {
            name: `${item.user.firstName} ${item.user.lastName}`.trim(),
            firstName: item.user.firstName,
            lastName: item.user.lastName
          } : null,
          allocator: item.allocator ? {
            name: `${item.allocator.firstName} ${item.allocator.lastName}`.trim(),
            firstName: item.allocator.firstName,
            lastName: item.allocator.lastName
          } : null,
          presentDomain: {
            id: item.presentDomain?.id,
            domain: item.presentDomain?.domain,
            dateOfUpload: item.presentDomain?.dateOfUpload,
            deadline: item.presentDomain?.deadline,
            edate: item.presentDomain?.edate,
            list: item.presentDomain?.list,
            uploader: item.presentDomain?.uploader ? {
              name: `${item.presentDomain.uploader.firstName} ${item.presentDomain.uploader.lastName}`.trim(),
              firstName: item.presentDomain.uploader.firstName,
              lastName: item.presentDomain.uploader.lastName
            } : null
          },
          socialWork: item.socialWork ? {
            id: item.socialWork.id,
            noLeadsAvailable: item.socialWork.noLeadsAvailable,
            reviewStatus: item.socialWork.reviewStatus,
            reviewRemark: item.socialWork.reviewRemark,
            dateOfWork: item.socialWork.dateOfWork,
            topLeads: item.socialWork.topLeads?.map(lead => ({
              id: lead.id,
              domain: lead.domain,
              domainSnapShotUrl: lead.domainSnapShotUrl,
              copyright: lead.copyright,
              description: lead.description,
              leadStatus: lead.leadStatus,
              socials: lead.socials?.map(social => ({
                id: social.id,
                platform: social.platform,
                status: social.status,
                followers: social.followers,
                employees: social.employees,
                url: social.url,
                userFullName: social.userFullName
              }))
            })),
            user: item.socialWork.user ? {
              name: `${item.socialWork.user.firstName} ${item.socialWork.user.lastName}`.trim(),
              firstName: item.socialWork.user.firstName,
              lastName: item.socialWork.user.lastName
            } : null,
            reviewer: item.socialWork.reviewer ? {
              name: `${item.socialWork.reviewer.firstName} ${item.socialWork.reviewer.lastName}`.trim(),
              firstName: item.socialWork.reviewer.firstName,
              lastName: item.socialWork.reviewer.lastName
            } : null
          } : null
        }));

        setData(transformedData);
        setTotalRowCount(response.data.totalElements || 0);
      }
    } catch (error) {
      console.error('Error fetching error data:', error);
      setSnackbar({
        open: true,
        message: 'Error fetching data',
        severity: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Optional: Prevent users from adding reviewStatus filter in MultiFilterDialog
  const handleApplyFilter = (newFilters) => {
    // Remove any reviewStatus filters that users might try to add
    const filteredFilters = newFilters.filter(filter => filter.id !== 'reviewStatus');
    setColumnFilters(filteredFilters);
    setOpenFilterDialog(false);
  };

  // Add this useEffect for data fetching
  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch]);

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2}>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Research Center
          </Typography>
          <Typography
            variant="h6"
            color='gray'
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            Error
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Stack>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MaterialReactTable table={table} />
            
            <MultiFilterDialog
              open={openFilterDialog}
              onClose={() => setOpenFilterDialog(false)}
              columns={columns.filter(col => col.accessorKey !== 'reviewStatus')}
              initialFilters={columnFilters}
              onApplyFilter={handleApplyFilter}
            />

            <MultiSortDialog
              open={openSortDialog}
              onClose={() => setOpenSortDialog(false)}
              columns={columns}
              initialSorting={sorting}
              onApplySort={(newSorting) => {
                setSorting(newSorting);
                setOpenSortDialog(false);
              }}
            />

            <Snackbar
              open={snackbar.open}
              autoHideDuration={2000}
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                severity={snackbar.severity}
              >
                {snackbar.message}
              </Alert>
            </Snackbar>
          </LocalizationProvider>
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

export default Error; 