import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    Snackbar,
    Alert,
    Divider
} from '@mui/material';
import { useCSVReader, formatFileSize, readString } from 'react-papaparse';

export default function UpdateDomain({ title, open, handleClose, setState, onSuccess }) {
    const [excelUrl, setExcelUrl] = useState('');
    const [urlError, setUrlError] = useState('');
    const [updateError, setUpdateError] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState({
        open: false,
        message: '',
        severity: 'success'
    });
    const [csvData, setCsvData] = useState([]);
    const [isCSVUploaded, setIsCSVUploaded] = useState(false);
    const { CSVReader } = useCSVReader();

    // Clear all states
    const resetStates = () => {
        setExcelUrl('');
        setUrlError('');
        setUpdateError('');
        setDisabled(true);
        setLoading(false);
        setCsvData([]);
        setIsCSVUploaded(false);
    };

    // Handle dialog close
    const handleDialogClose = () => {
        resetStates();
        handleClose();
    };

    const validateGoogleSheetsUrl = (url) => {
        const regex = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/e\/[^\/]+\/pub\?output=csv$/;
        return regex.test(url);
    };

    const validateHeaders = (dataArray, requiredHeaders) => {
        if (dataArray.length === 0) return false;
        const firstRow = dataArray[0];
        return requiredHeaders.every(header => Object.hasOwnProperty.call(firstRow, header));
    };

    const handleExcelUrlChange = (e) => {
        const url = e.target.value;
        setExcelUrl(url);
        if (validateGoogleSheetsUrl(url)) {
            setUrlError('');
            setDisabled(false);
        } else if (url === '') {
            setUrlError('');
            setDisabled(true);
        } else {
            setUrlError('URL must be of the format: https://docs.google.com/spreadsheets/d/e/<YOUR-DOCUMENT-LINK>/pub?output=csv');
            setDisabled(true);
        }
    };

    const handleCSVUpload = (results) => {
        setCsvData(results.data);
        setIsCSVUploaded(true);
        setDisabled(false);
    };

    const parseCSV = (csvData) => {
        return new Promise((resolve, reject) => {
            readString(csvData, {
                header: true,
                skipEmptyLines: true,
                transformHeader: header => header.toLowerCase().replace(/\s+/g, ''),
                complete: (results) => {
                    resolve(results.data);
                },
                error: (error) => {
                    reject(error);
                }
            });
        });
    };

    const handleUpdate = async () => {
        if (!excelUrl && !isCSVUploaded) return;

        setLoading(true);
        setUpdateError('');

        try {
            let dataToProcess = csvData;

            if (excelUrl) {
                const csvResponse = await fetch(excelUrl);
                const csvData = await csvResponse.text();
                dataToProcess = await parseCSV(csvData);
            }

            const requiredHeaders = ['domain'];
            if (!validateHeaders(dataToProcess, requiredHeaders)) {
                throw new Error('The CSV file must contain "Domain" headers.');
            }

            if (setState) {
                setState(dataToProcess);
            }

            setTimeout(() => {
                handleDialogClose();
            }, 1000);

        } catch (error) {
            console.error('Error updating domains:', error);
            setUpdateError(error.message || 'Failed to update domains. Please try again.');
            setSnackbar({
                open: true,
                message: error.message || 'Failed to update domains',
                severity: 'error'
            });
        } finally {
            setLoading(false);
        }
    };

    const downloadTemplate = () => {
        const templateData = [
            {
                "portfolio": "LTD",
                "platform": "NC",
                "edate": "2025-03-09",
                "domain": "BlueSkyAndHardRock.com",
                "host": "Name",
                "tld": "com",
                "wby": "2024-02-07",
                "aby": "2011-12-03",
                "reg": "1",
                "regx": "1",
                "sg": "0",
                "cpc": "0",
                "bids": "1",
                "currentprice": "2",
                "est": "0",
                "esw": "0",
                "tldc": "1",
                "tldd": "0",
                "auctiontype": "",
                "tags": "partner"
            },
            {
                "portfolio": "LTD",
                "platform": "NC",
                "edate": "2025-03-05",
                "domain": "umrahjannahfirdaus.com",
                "host": "Name",
                "tld": "com",
                "wby": "2024-02-02",
                "aby": "2024-04-02",
                "reg": "1",
                "regx": "1",
                "sg": "0",
                "cpc": "0",
                "bids": "1",
                "currentprice": "2",
                "est": "0",
                "esw": "0",
                "tldc": "1",
                "tldd": "1",
                "auctiontype": "",
                "tags": "partner"
            }
        ];

        const csvContent = "data:text/csv;charset=utf-8," +
            ["portfolio,platform,edate,domain,host,tld,wby,aby,reg,regx,sg,cpc,bids,currentprice,est,esw,tldc,tldd,auctiontype,tags"]
                .concat(templateData.map(e => Object.values(e).join(",")))
                .join("\n");

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "template.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleDialogClose}
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 2,
                        boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                        overflow: 'hidden'
                    }
                }}
            >
                <DialogTitle
                    sx={{
                        py: 2,
                        px: 3,
                        typography: 'h6',
                        borderBottom: '1px solid #e0e0e0',
                        color: '#1976d2',
                        backgroundColor: '#fff'
                    }}
                >
                    {title}
                </DialogTitle>
                <DialogContent sx={{ p: 3, backgroundColor: '#f8f9fa' }}>
                    <Stack
                        direction="row"
                        spacing={3}
                        divider={
                            <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ mx: 1 }}
                            />
                        }
                        sx={{
                            p: 3,
                            mt: 2,
                            minHeight: '200px',
                            backgroundColor: '#fff',
                            borderRadius: 2,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.04)'
                        }}
                    >
                        <Box width="50%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: -1, fontWeight: 500, color: '#1976d2' }}>
                                Upload by Sheet
                            </Typography>
                            <TextField
                                size="small"
                                fullWidth
                                placeholder="Google Sheet URL"
                                variant="outlined"
                                value={excelUrl}
                                onChange={handleExcelUrlChange}
                                error={!!urlError}
                                helperText={urlError || "Paste your published Google Sheets URL (entire doc. + .csv)"}
                                disabled={loading}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        height: '75px',
                                        fontSize: '0.875rem',
                                        backgroundColor: '#fff',
                                        borderRadius: '15px',
                                        '&:hover': {
                                            backgroundColor: '#f8f9fa'
                                        }
                                    },
                                    '& .MuiFormHelperText-root': {
                                        fontSize: '0.75rem',
                                        marginLeft: 0,
                                    },
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.875rem',
                                    }
                                }}
                            />
                            {updateError && (
                                <Typography
                                    color="error"
                                    variant="caption"
                                    sx={{
                                        pl: 0.5,
                                        fontSize: '0.75rem',
                                    }}
                                >
                                    {updateError}
                                </Typography>
                            )}
                        </Box>

                        <Box width="50%" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Typography variant="subtitle1" sx={{ mb: -1, fontWeight: 500, color: '#1976d2' }}>
                                Upload by CSV
                            </Typography>
                            <CSVReader
                                onUploadAccepted={handleCSVUpload}
                                config={{
                                    worker: false,
                                    header: true,
                                    transformHeader: (header) => header.toLowerCase().replace(/\s+/g, ''),
                                }}
                            >
                                {({
                                    getRootProps,
                                    acceptedFile,
                                    ProgressBar,
                                    getRemoveFileProps,
                                    Remove,
                                }) => (
                                    <div
                                        {...getRootProps()}
                                        style={{
                                            border: '2px dashed #CCC',
                                            fontSize: '0.875rem',
                                            height: '120px',
                                            borderRadius: '20px',
                                            padding: '16px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            backgroundColor: '#fff',
                                            transition: 'all 0.2s ease-in-out',
                                            '&:hover': {
                                                backgroundColor: '#f8f9fa',
                                                borderColor: '#1976d2',
                                                borderStyle: 'dashed'
                                            }
                                        }}
                                    >
                                        {acceptedFile ? (
                                            <div>
                                                <span>{acceptedFile.name}</span>
                                                <ProgressBar />
                                                <div {...getRemoveFileProps()}>
                                                    <Remove />
                                                </div>
                                            </div>
                                        ) : (
                                            "Drop CSV file here or click to upload"
                                        )}
                                    </div>
                                )}
                            </CSVReader>
                        </Box>
                    </Stack>

                    <Button
                        variant="outlined"
                        onClick={downloadTemplate}
                        fullWidth
                        sx={{
                            mt: 3,
                            borderRadius: 2,
                            boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
                            textTransform: 'none',
                            fontWeight: 500,
                            borderColor: '#1976d2',
                            color: '#1976d2',
                            '&:hover': {
                                borderColor: '#1976d2',
                                backgroundColor: 'rgba(25, 118, 210, 0.04)'
                            }
                        }}
                    >
                        Download Template
                    </Button>
                </DialogContent>
                <DialogActions
                    sx={{
                        py: 2,
                        px: 3,
                        borderTop: '1px solid #e0e0e0',
                        backgroundColor: '#fff'
                    }}
                >
                    <Button
                        onClick={handleDialogClose}
                        variant="outlined"
                        sx={{
                            textTransform: 'none',
                            fontWeight: 500,
                            borderColor: '#1976d2',
                            color: '#1976d2',
                            '&:hover': {
                                borderColor: '#1976d2',
                                backgroundColor: 'rgba(25, 118, 210, 0.04)'
                            }
                        }}
                        disabled={loading}
                    >
                        Cancel
                    </Button>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        variant="contained"
                        disabled={disabled || loading}
                        onClick={handleUpdate}
                        sx={{
                            textTransform: 'none',
                            fontWeight: 500,
                            px: 3,
                            boxShadow: 'none',
                            '&:hover': {
                                boxShadow: 'none'
                            }
                        }}
                    >
                        {loading ? 'Updating...' : 'Update'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={2000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={snackbar.severity}
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    sx={{
                        width: '100%',
                        fontSize: '0.75rem',
                        '& .MuiAlert-message': {
                            padding: '5px 0'
                        }
                    }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}