import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';

function OverwriteDialog({
  open,
  onClose,
  existingDomains,
  selectedDomains,
  onSelectAll,
  onDomainSelect,
  onOverwrite
}) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '8px',
          boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
          overflow: 'hidden',
          maxWidth: '600px',
          margin: '16px'
        }
      }}
    >
      <DialogTitle
        sx={{
          py: 2,
          px: 3,
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#fff'
        }}
      >
        <Typography
          sx={{
            fontSize: '1.1rem',
            fontWeight: 600,
            color: '#2f3542'
          }}
        >
          Existing Domains
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ 
        p: 3, 
        backgroundColor: '#f8f9fa',
        maxHeight: '400px'
      }}>
        <DialogContentText sx={{
          fontSize: '0.875rem',
          color: '#666',
          mb: 2,
          mt: 1,
          fontWeight: 500
        }}>
          The following domains already exist and were not uploaded:
        </DialogContentText>

        <Box sx={{
          p: 3,
          backgroundColor: '#fff',
          borderRadius: '6px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.04)',
          maxHeight: '280px',
          overflow: 'auto'
        }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedDomains.length === existingDomains.length}
                onChange={onSelectAll}
                sx={{
                  color: '#8797A8',
                  '&.Mui-checked': {
                    color: '#1976d2'
                  }
                }}
              />
            }
            label={
              <Typography sx={{
                fontSize: '0.875rem',
                fontWeight: 600,
                color: '#2f3542'
              }}>
                Select All
              </Typography>
            }
          />

          <Box
            display="flex"
            flexWrap="wrap"
            sx={{
              mt: 2,
              mx: -1
            }}
          >
            {existingDomains.map((domain, index) => (
              <Box
                key={index}
                sx={{
                  width: '33.33%',
                  px: 1,
                  mb: 1
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedDomains.includes(domain)}
                      onChange={onDomainSelect}
                      value={domain}
                      sx={{
                        color: '#8797A8',
                        '&.Mui-checked': {
                          color: '#1976d2'
                        }
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: '0.875rem',
                        color: '#666',
                        wordBreak: 'break-word',
                        lineHeight: 1.3
                      }}
                    >
                      {domain}
                    </Typography>
                  }
                />
              </Box>
            ))}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{
        py: 2,
        px: 3,
        borderTop: '1px solid #e0e0e0',
        backgroundColor: '#fff'
      }}>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            borderColor: '#1976d2',
            color: '#1976d2',
            '&:hover': {
              borderColor: '#1976d2',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          Skip Duplicates
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          onClick={onOverwrite}
          variant="contained"
          sx={{
            textTransform: 'none',
            fontWeight: 500,
            px: 3,
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none'
            }
          }}
        >
          Overwrite
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OverwriteDialog; 