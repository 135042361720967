import Dexie from "dexie";

const db = new Dexie('leadWorkStationDB');

// Define a schema for the database
db.version(4).stores({
    presentAllocs: 'id, dateOfAlloc, domain, working, status, pendingLeads, todayDW',
    data:'id, date, changes, ld, lastWorkSaved, changesPending, ldPending, lastWorkSavedPending'
});

export default db;