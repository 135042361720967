import { Alert, Autocomplete, Box, Button, Dialog, DialogContentText, FormControlLabel, FormGroup, Snackbar, Stack, Switch, Tab, Tabs, TextField, ThemeProvider, Typography, IconButton, InputAdornment, InputLabel, MenuItem, Select, FormControl, Badge, Tooltip } from "@mui/material";
import { theme } from "../Theme";
import React, { useEffect, useState, useMemo, useCallback, memo } from "react";
import { allocateSocialToProcessor, allocateToProcessor, allocateToTeam, getMySocialAllocations, getNonAlloc, getSocialChildren, getUserNTeams, uploadDomains, uploadSocialDomains } from "../api";
import { Add, DeleteOutline, FiberManualRecord, PanToolAltOutlined, ShortcutOutlined, ContentCopyOutlined, FileDownloadOutlined, FilterList as FilterListIcon, Search as SearchIcon, Sort as SortIcon, Stop, Create, DoneOutline, Person } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import MultiSortDialog from '../Common/MultiSortDialog';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";

// Add these styles at the top level
const commonButtonStyles = {
  width: '28px',
  height: '28px',
  color: '#8797A8',
  backgroundColor: '#ffffff',
  border: '1px solid #e0e0e0',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#8797A8',
  },
  '&.Mui-disabled': {
    backgroundColor: '#f5f5f5',
    borderColor: '#e0e0e0',
    color: '#bdbdbd',
  },
};

// Add this StatusIcon component at the top level
const StatusIcon = memo(({ status }) => {
  if (status === "not started") {
    return (
      <Tooltip title="Not Started Processing">
        <Stop sx={{
          color: '#8797A8',
          fontSize: '1.2rem'
        }} />
      </Tooltip>
    );
  } else if (status === "processing") {
    return (
      <Tooltip title="Processing">
        <Create sx={{
          color: '#FFC0CB',
          fontSize: '1.2rem'
        }} />
      </Tooltip>
    );
  } else if (status === "submitted") {
    return (
      <Tooltip title="Processed">
        <DoneOutline sx={{
          color: '#4CAF50',
          fontSize: '1.2rem'
        }} />
      </Tooltip>
    );
  }
  return null;
});

export default function MyClaims() {
  // Add these new state variables near the top with other state declarations

  const [children, setChildren] = useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const [agent, setAgent] = useState('');
  const [allocating, setAllocating] = useState(false);

  // Table states
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50 });
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [searchText, setSearchText] = useState('');

  // Dialog states
  const [multiSortDialogOpen, setMultiSortDialogOpen] = useState(false);
  const [multiFilterDialogOpen, setMultiFilterDialogOpen] = useState(false);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const columns = useMemo(() => [
    {
      accessorKey: 'dateOfAlloc',
      header: 'Date',
      size: 100,
      accessorFn: (row) => {
        try {
          return row.dateOfAlloc ? new Date(row.dateOfAlloc) : null;
        } catch (e) {
          return null;
        }
      },
      Cell: ({ cell }) => {
        try {
          const date = cell.getValue();
          if (!date || !(date instanceof Date) || isNaN(date)) return '-';

          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          const year = date.getFullYear();
          const currentYear = new Date().getFullYear();

          return currentYear === year ?
            `${day}-${month}` :
            `${day}-${month}-${year.toString().slice(2)}`;
        } catch (e) {
          return '-';
        }
      }
    },
    {
      accessorKey: 'domain',
      header: 'Domain',
      size: 210,
      accessorFn: (row) => row.presentDomain?.domain || '-'
    },
    {
      accessorKey: 'status',
      header: 'Status',
      size: 80,
      accessorFn: (row) => row.status,
      Cell: ({ cell }) => <StatusIcon status={cell.getValue() ?? "not started"} />
    },
    {
      accessorKey: 'deadline',
      header: 'Deadline',
      size: 100,
      accessorFn: (row) => {
        try {
          return row.presentDomain?.deadline ? new Date(row.presentDomain.deadline) : null;
        } catch (e) {
          return null;
        }
      },
      Cell: ({ cell }) => {
        try {
          const date = cell.getValue();
          if (!date || !(date instanceof Date) || isNaN(date)) return '-';

          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          const year = date.getFullYear();
          const currentYear = new Date().getFullYear();

          return currentYear === year ?
            `${day}-${month}` :
            `${day}-${month}-${year.toString().slice(2)}`;
        } catch (e) {
          return '-';
        }
      }
    },
    {
      accessorKey: 'edate',
      header: 'E-Date',
      size: 100,
      accessorFn: (row) => {
        try {
          return row.presentDomain?.edate ? new Date(row.presentDomain.edate) : null;
        } catch (e) {
          return null;
        }
      },
      Cell: ({ cell }) => {
        try {
          const date = cell.getValue();
          if (!date || !(date instanceof Date) || isNaN(date)) return '-';

          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          const year = date.getFullYear();
          const currentYear = new Date().getFullYear();

          return currentYear === year ?
            `${day}-${month}` :
            `${day}-${month}-${year.toString().slice(2)}`;
        } catch (e) {
          return '-';
        }
      }
    },
    {
      accessorKey: 'list',
      header: 'List',
      size: 70,
      accessorFn: (row) => row.presentDomain?.list || '-',
      filterFn: 'equals'
    },
    {
      accessorKey: 'allocator',
      header: 'Allocated by',
      size: 100,
      Cell: ({ row }) => {
        const user = row.original.allocator;
        if (user) {
          return (
            <Tooltip title={`${user.firstName} ${user.lastName}`} placement="bottom">
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Person sx={{ color: '#FFA726', fontSize: '1.2rem' }} />
                <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic' }}>
                  {`${user.firstName} ${user.lastName}`}
                </Typography>
              </Box>
            </Tooltip>
          );
        }
        return (
          <Tooltip title="Not Allocated" placement="bottom">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Person sx={{ color: '#9E9E9E', fontSize: '1.2rem' }} />
              <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic', color: '#9E9E9E' }}>
                Unallocated
              </Typography>
            </Box>
          </Tooltip>
        );
      }
    }
  ], []);

  const fetchData = async (
    pagination,
    sorting,
    filters,
    globalFilter
  ) => {
    setLoading(true);
    try {
      // Transform filters into the expected format
      const transformedFilters = filters.reduce((acc, filter) => {
        if (filter.id && filter.operator) {
          acc[filter.id] = {
            operator: filter.operator,
            value: filter.value,
            value2: filter.value2,
            type: filter.type
          };
        }
        return acc;
      }, {});

      // Transform sorting into the expected format
      const transformedSorting = sorting.reduce((acc, sort) => {
        acc[sort.id] = sort.desc ? 'desc' : 'asc';
        return acc;
      }, {});

      const response = await getMySocialAllocations({
        page: pagination.pageIndex,
        size: pagination.pageSize,
        filter: JSON.stringify(transformedFilters),
        sort: JSON.stringify(transformedSorting),
        search: globalFilter
      });

      if (response.data) {
        setData(response.data.content || []);
        setTotalElements(response.data.totalElements || 0);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch data',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(pagination, sorting, columnFilters, globalSearch);
  }, [pagination, sorting, columnFilters, globalSearch]);

  useEffect(() => {
    getSocialChildren().then((res) => { setChildren(res.data); }).catch((err) => { console.log(err) })
  }, [])

  const handleExportData = useCallback((selectedRows) => {
    try {
      // Get all column accessorKeys from the columns definition, excluding leads and socials
      const exportColumns = columns
        .filter(col => col.accessorKey && !['leads', 'socials', 'allocator'].includes(col.accessorKey))
        .map(col => ({
          key: col.accessorKey,
          accessorFn: col.accessorFn
        }));

      // Create CSV header
      const header = exportColumns.map(col => col.key).join(',') + '\n';

      // Create CSV content
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          let value;
          if (col.accessorFn) {
            // Use accessorFn if defined
            value = col.accessorFn(row.original);
            // Format dates consistently with table display (DD-MMM)
            if (value instanceof Date) {
              value = value.toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short'
              }).replace(/ /g, '-');
            }
          } else {
            value = row.original[col.key];
          }

          // Handle null/undefined values
          if (value === null || value === undefined) return '';
          // Escape strings containing commas
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      const csv = header + csvContent;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `myclaim_export_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      setSnackbar({
        open: true,
        message: 'Data exported successfully',
        severity: 'success'
      });
    } catch (error) {
      console.error('Export error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to export data',
        severity: 'error'
      });
    }
  }, [columns]);

  const handleCopyDomains = (selectedRows) => {
    try {
      const domains = selectedRows.map(row => row.original.domainName).join('\n');
      navigator.clipboard.writeText(domains);
      setSnackbar({
        open: true,
        message: 'Domains copied to clipboard',
        severity: 'success'
      });
    } catch (error) {
      console.error('Copy error:', error);
      setSnackbar({
        open: true,
        message: 'Failed to copy domains',
        severity: 'error'
      });
    }
  };

  // Add these handlers
  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setMultiFilterDialogOpen(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setMultiSortDialogOpen(false);
  };

  const handleSearch = useCallback(() => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  }, [searchText]);

  // Add this handler
  const handleAgentChange = (event) => {
    setAgent(event.target.value);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
  };

  // Add debounced search effect
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (!searchText || searchText.length >= 3) {
        setGlobalSearch(searchText);
      }
    }, 300); // 300ms delay

    return () => clearTimeout(debounceTimeout);
  }, [searchText]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalElements,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    state: {
      pagination,
      sorting,
      columnFilters,
      globalFilter: globalSearch,
      isLoading: loading,
    },
    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 },
      sorting: [],
    },
    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const activeFiltersCount = columnFilters.length;
      const activeSortsCount = sorting.length;

      return (
        <Stack direction="row" spacing={1} alignItems="center" width="100%">
          {children.length > 0 && (
            <Button
              disabled={selectedRows.length === 0}
              variant="outlined"
              startIcon={<PanToolAltOutlined />}
              onClick={() => setOpenAllocate(true)}
              sx={{
                textTransform: 'none',
                backgroundColor: '#8797A8',
                color: '#FFFFFF !important',
                padding: '4px 8px',
                minWidth: 'auto',
                '&:hover': {
                  backgroundColor: '#8797A8',
                  opacity: 0.9,
                },
                '&.Mui-disabled': {
                  backgroundColor: '#8797A8',
                  opacity: 0.7,
                }
              }}
            >
              Allocate
            </Button>
          )}
          <Box flexGrow={1} />
          <TextField
            placeholder="Search domains..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') handleSearch();
            }}
            size="small"
            sx={{
              minWidth: '100px',
              '& .MuiOutlinedInput-root': {
                height: '30px',
                fontSize: '0.875rem',
                backgroundColor: '#ffffff',
                borderRadius: '20px',
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#8797A8',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderRadius: '20px',
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      fontSize: '1.2rem',
                      color: '#8797A8',
                      cursor: 'pointer',
                      '&:hover': {
                        color: '#6b7a8a',
                      },
                    }}
                    onClick={handleSearch}
                  />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ display: 'flex', gap: '4px' }}>
            <Tooltip title={`Sort (${activeSortsCount} active)`} arrow placement="top">
              <Badge
                badgeContent={activeSortsCount}
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    fontSize: '0.5rem',
                    height: '12px',
                    minWidth: '12px',
                    padding: '0 3px'
                  }
                }}
              >
                <IconButton
                  onClick={() => setMultiSortDialogOpen(true)}
                  size="small"
                  sx={commonButtonStyles}
                >
                  <SortIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Badge>
            </Tooltip>
            <Tooltip title={`Filter (${activeFiltersCount} active)`} arrow placement="top">
              <Badge
                badgeContent={activeFiltersCount}
                color="primary"
                sx={{
                  '& .MuiBadge-badge': {
                    fontSize: '0.5rem',
                    height: '12px',
                    minWidth: '12px',
                    padding: '0 3px'
                  }
                }}
              >
                <IconButton
                  onClick={() => setMultiFilterDialogOpen(true)}
                  size="small"
                  sx={commonButtonStyles}
                >
                  <FilterListIcon sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Badge>
            </Tooltip>
            <Tooltip title="Copy" arrow placement="top">
              <IconButton
                onClick={() => handleCopyDomains(selectedRows)}
                disabled={selectedRows.length === 0}
                size="small"
                sx={commonButtonStyles}
              >
                <ContentCopyOutlined sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Export" arrow placement="top">
              <IconButton
                onClick={() => handleExportData(selectedRows)}
                disabled={selectedRows.length === 0}
                size="small"
                sx={commonButtonStyles}
              >
                <FileDownloadOutlined sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      );
    },

    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Stack direction='column' spacing={5}>
        <Stack direction='row' spacing={2}>
          <Typography
            variant="h6"
            sx={{
              textAlign: 'left',
              fontWeight: 'bold',
              position: 'relative',
            }}
          >
            My Claims
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
        <Box>
          <MaterialReactTable table={table} />
          <MultiSortDialog
            open={multiSortDialogOpen}
            onClose={() => setMultiSortDialogOpen(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />
          <MultiFilterDialog
            open={multiFilterDialogOpen}
            onClose={() => setMultiFilterDialogOpen(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />
          <Snackbar
            open={snackbar.open}
            autoHideDuration={2000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={() => setSnackbar({ ...snackbar, open: false })}
              severity={snackbar.severity}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>

        <Dialog fullWidth open={openAllocate} onClose={handleCloseAllocate}>
          <DialogTitle>
            <Stack direction='row'>
              <Typography variant="h6">Allocate to Processor</Typography>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack direction='row' width='100%' justifyContent='space-between'>
              <DialogContentText>
                {table.getSelectedRowModel().rows.length} domain{table.getSelectedRowModel().rows.length !== 1 && 's'} selected
              </DialogContentText>
            </Stack>
            <FormGroup sx={{ mt: 2 }}>
              <Stack direction='row' spacing={2}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="processor-label">Select Agent</InputLabel>
                  <Select
                    labelId="processor-label"
                    id="processor-select"
                    value={agent}
                    label="Select Agent"
                    onChange={handleAgentChange}
                  >
                    {children.map((child) => (
                      <MenuItem key={child.email} value={child.email}>
                        {child.firstName + " " + child.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAllocate}>Cancel</Button>
            <Button
              variant="contained"
              disabled={allocating}
              onClick={() => {
                setAllocating(true);
                const selectedIds = table.getSelectedRowModel().rows.map(row => row.original.id);
                allocateSocialToProcessor(selectedIds, agent)
                  .then((res) => {
                    setSnackbar({
                      open: true,
                      message: 'Successfully allocated domains',
                      severity: 'success'
                    });
                    fetchData(pagination, sorting, columnFilters, globalSearch);
                    setAgent('');
                    handleCloseAllocate();
                    table.resetRowSelection();
                    setAllocating(false);
                  })
                  .catch((err) => {
                    setSnackbar({
                      open: true,
                      message: 'Failed to allocate domains',
                      severity: 'error'
                    });
                    setAllocating(false);
                  });
              }}
            >
              {allocating ? "Allocating..." : "Allocate"}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </ThemeProvider>
  );
}