import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Snackbar, Stack, Switch, ThemeProvider, Typography, Tooltip, Checkbox, Tabs, Tab, OutlinedInput, ListItemText, Chip, Badge, IconButton } from "@mui/material";
import { theme } from '../Theme';
import React, { useEffect, useMemo, useState, useCallback } from "react";
import UploadCSVDialog from "../UploadCSVDialog";
import UpdateDomain from "../Common/UpdateDomain";
import UploadCSVDialogLtdDomains from "../Common/UploadCSVDialogLtdDomains";
import { isAdmin, canAddLtd } from '../msalService';
import { Add, Edit, PanToolAltOutlined, Sort as SortIcon, Person, Groups, FileDownloadOutlined } from "@mui/icons-material";
import { allocateDomainToProcessor, allocateDomainToTeam, editLtdDomains, getAllLtdDomains, getAllLtdDomainsWithPagination, getAllOwnedDomain, getAllTags, uploadDomains, uploadLtdDomains, uploadOwnedDomains, allocateDomainToTeams, allocateDomainToProcessors, getAllPortfolioDomains, getAllNewPortfolioDomains } from "../api";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUser } from '../UserProvider';
import FilterListIcon from '@mui/icons-material/FilterList';
import MultiFilterDialog from '../Common/MultiFilterDialog';
import MultiSortDialog from '../Common/MultiSortDialog';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import AllocationDialog from '../Common/AllocationDialog';
import OverwriteDialog from '../Common/OverwriteDialog';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomToggleButton from "../Common/CustomToggleButton";

export default function LtdStudioDeleted() {

  const [domains, setDomains] = useState([]);
  const [editDomains, setEditDomains] = useState([]);
  const [data, setData] = useState([]);
  const [tags, setTags] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog1, setOpenDialog1] = useState(false);
  const [upsuc, setUpsuc] = useState(false);
  const [upfail, setUpfail] = useState(false);
  const [upsuc1, setUpsuc1] = useState(false);
  const [upfail1, setUpfail1] = useState(false);

  let [idds, setIdds] = React.useState([]);
  const [openAllocate, setOpenAllocate] = useState(false);
  const { user, updateUser } = useUser();

  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 50, });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [openSortDialog, setOpenSortDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [globalSearch, setGlobalSearch] = useState('');

  const [openExistingDomainsDialog, setOpenExistingDomainsDialog] = useState(false);
  const [existingDomains, setExistingDomains] = useState([]);
  const [selectedExistingDomains, setSelectedExistingDomains] = useState([]);

  const [ltdTab, setLtdTab] = useState('ALL');
  const [showNew24h, setShowNew24h] = useState(false);

  const handleTabChange = (event, newValue) => {
    setLtdTab(newValue);
  };

  const handleSearch = () => {
    if (!searchText || searchText.length >= 3) {
      setGlobalSearch(searchText);
    }
  };

  const fetchData = useCallback((
    filters = columnFilters,
    sortingRules = sorting,
    paginationRules = pagination,
    searchQuery = globalSearch
  ) => {
    setLoading(true);

    // Transform filters into the expected format
    const transformedFilters = filters.reduce((acc, filter) => {
      if (filter.id && filter.operator) {
        acc[filter.id] = {
          operator: filter.operator,
          value: filter.value,
          value2: filter.value2,
          type: filter.type
        };
      }
      return acc;
    }, {
      // Add ltdState and platform to filters
      ...((ltdTab !== 'ALL' && ltdTab !== 'ALD' && ltdTab !== 'PD') && {
        platform: {
          operator: 'equals',
          type: 'text',
          value: ltdTab.toLowerCase()
        }
      })
    });

    // Transform sorting into the expected format
    const transformedSorting = sortingRules.reduce((acc, sort) => {
      acc[sort.id] = sort.desc ? 'desc' : 'asc';
      return acc;
    }, {});

    let portfolio = 'ltd prev';
    if (ltdTab === 'ALD' || ltdTab === 'PD') {
      portfolio = ltdTab.toLowerCase() + ' prev';
    }
  
    const apiParams = {
      page: paginationRules.pageIndex,
      size: paginationRules.pageSize,
      filter: JSON.stringify(transformedFilters),
      sort: JSON.stringify(transformedSorting),
      search: searchQuery,
      portfolio: portfolio
    };

    const apiCall = showNew24h ? getAllNewPortfolioDomains : getAllPortfolioDomains;
  
    try {
      apiCall(apiParams)
        .then(response => {
          if (response && response.data) {
            setData(response.data.content);
            setTotalRowCount(response.data.totalElements);
          }
        })
        .catch(error => {
          console.error('API call failed:', error);
          setData([]);
          setTotalRowCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error in fetchData:', error);
      setLoading(false);
    }
  }, [columnFilters, sorting, pagination, globalSearch, ltdTab, showNew24h]);

  // Add handlers for filter and sort dialogs
  const handleFilterApply = (newFilters) => {
    setColumnFilters(newFilters);
    setOpenFilterDialog(false);
  };

  const handleSortApply = (newSorting) => {
    setSorting(newSorting);
    setOpenSortDialog(false);
  };

  useEffect(() => {
    fetchData();
  }, [pagination.pageIndex, pagination.pageSize, sorting, columnFilters, globalSearch, ltdTab, showNew24h]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleClose1 = () => {
    setOpenDialog1(false);
  };

  const handleCloseAllocate = () => {
    setOpenAllocate(false);
    setIdds([]);
    setExistingDomains([]);
    setSelectedExistingDomains([]);
    setOpenExistingDomainsDialog(false);
  };

  useEffect(() => {
    console.log(editDomains);
    if (editDomains && editDomains.length > 0)
      editLtdDomains(editDomains)
        .then((res) => {
          console.log(res);
          setUpsuc(true);
          setEditDomains([]);
          fetchData();
        })
        .catch((err) => {
          console.log(err);
          setUpfail(true);
        });
  }, [editDomains]);

  const handleSelectAll = (event) => {
    setSelectedExistingDomains(event.target.checked ? existingDomains : []);
  };

  const handleExistingDomainSelect = (event) => {
    const { value } = event.target;
    setSelectedExistingDomains((prev) =>
      prev.includes(value)
        ? prev.filter((domain) => domain !== value)
        : [...prev, value]
    );
  };

  const handleCloseExistingDomainsDialog = () => {
    setOpenExistingDomainsDialog(false);
    setSelectedExistingDomains([]);
    setExistingDomains([]);
    setDomains([]);
    if (idds.length > 0) {
      setOpenAllocate(true);
    }
  };

  const handleUploadDomains = async (domains, overwrite = false) => {
    try {
      const res = await uploadLtdDomains(domains, overwrite);
      const newIds = res.data.savedDomainIds;
      setIdds((prevIds) => Array.from(new Set([...prevIds, ...newIds])));

      if (res.data.existingDomains?.length > 0) {
        setExistingDomains(res.data.existingDomains);
        setOpenExistingDomainsDialog(true);
      } else {
        setOpenAllocate(true);
        setUpsuc(true);
        setDomains([]);
        handleCloseExistingDomainsDialog();
      }
      fetchData();
    } catch (err) {
      console.log(err);
      setDomains([]);
      setUpfail(true);
    }
  };

  useEffect(() => {
    if (domains && domains.length > 0) {
      handleUploadDomains(domains);
    }
  }, [domains]);

  useEffect(() => {
    fetchData();
    getAllTags()
      .then((res) => {
        const arr = res.data.map((ar) => ar.tag);
        console.log(arr);
        setTags(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [pagination.pageIndex, pagination.pageSize]);

  const commonButtonStyles = {
    width: '28px',
    height: '28px',
    color: '#8797A8',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      borderColor: '#8797A8',
    },
    '&.Mui-disabled': {
      backgroundColor: '#f5f5f5',
      borderColor: '#e0e0e0',
      color: '#bdbdbd',
    },
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "dateOfUpload",
        header: "adate",
        size: 60,
        accessorFn: (row) => {
          const date = row.dateOfUpload ? new Date(row.dateOfUpload) : null;
          return date;
        },
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          if (!date) return '-';
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        }
      },
      {
        accessorKey: "edate",
        header: "edate",
        size: 60,
        accessorFn: (row) => {
          const date = row.edate ? new Date(row.edate) : null;
          return date;
        },
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          if (!date) return '-';
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        }
      },
      {
        accessorKey: "domain",
        header: "domain",
        size: 120,
        accessorFn: (row) => row.domain || '-'
      },
      {
        accessorKey: "type",
        header: "type",
        size: 60,
        accessorFn: (row) => row.type || '-'
      },
      {
        accessorKey: "lander",
        header: "lander",
        size: 60,
        accessorFn: (row) => row.lander || '-'
      },
      {
        accessorKey: "host",
        header: "host",
        size: 50,
        accessorFn: (row) => row.host || '-'
      },
      {
        accessorKey: "wby",
        header: "wby",
        size: 50,
        accessorFn: (row) => {
          const wby = row.wby;
          if (wby?.length >= 4) {
            return new Date().getFullYear() - parseInt(wby.substring(0, 4), 10);
          }
          return '-';
        }
      },
      {
        accessorKey: "aby",
        header: "aby",
        size: 40,
        accessorFn: (row) => {
          const aby = row.aby;
          if (aby?.length >= 4) {
            return new Date().getFullYear() - parseInt(aby.substring(0, 4), 10);
          }
          return '-';
        }
      },
      {
        accessorKey: "reg",
        header: "reg",
        size: 40,
        accessorFn: (row) => row.reg !== null && row.reg !== -1 ? row.reg : '-'
      },
      {
        accessorKey: "regx",
        header: "Regx",
        size: 40,
        accessorFn: (row) => row.regx !== null && row.regx !== -1 ? row.regx : '-'
      },
      {
        accessorKey: 'tld_count',
        header: 'tldc',
        size: 50,
        accessorFn: (row) => row?.tld_count || '-'
      },
      {
        accessorKey: 'tld_count_dev',
        header: 'tldd',
        size: 50,
        accessorFn: (row) => row?.tld_count_dev || '-'
      },
      {
        accessorKey: "sg",
        header: "sg",
        size: 50,
        enableSorting: true,
        accessorFn: (row) => row.sg !== null && row.sg !== -1 ? row.sg : '-'
      },
      {
        accessorKey: "cpc",
        header: "cpc",
        size: 50,
        accessorFn: (row) => row.cpc !== null && row.cpc !== -1 ? row.cpc.toFixed(1) : '-'
      },
      {
        accessorKey: "bids",
        header: "bids",
        size: 50,
        accessorFn: (row) => {
          return row.bids !== null && row.bids !== 0 ? (row.bids < 100 ? row.bids : Math.round(row.bids)) : '-';
        }
      },
      {
        accessorKey: "currentPrice",
        header: "price",
        size: 60,
        accessorFn: (row) => {
          return row.currentPrice !== null && row.currentPrice !== 0 ? (row.currentPrice < 100 ? row.currentPrice : Math.round(row.currentPrice)) : '-';
        }
      },
      {
        accessorKey: "EST",
        header: "est",
        size: 60,
        accessorFn: (row) => {
          return row.EST !== null && row.EST !== -1 ? (row.EST < 100 ? row.EST : Math.round(row.EST / 100) * 100) : '-';
        }
      },
      {
        accessorKey: "GDV",
        header: "gdv",
        size: 50,
        accessorFn: (row) => {
          return row.GDV !== null && row.GDV !== -1 ? (row.GDV < 100 ? row.GDV : Math.round(row.GDV / 100) * 100) : '-';
        }
      },
      {
        accessorKey: "ESW",
        header: "esw",
        size: 50,
        accessorFn: (row) => {
          return row.ESW !== null && row.ESW !== 0 ? (row.ESW < 100 ? row.ESW : Math.round(row.ESW / 100) * 100) : '-';
        }
      },
      {
        accessorKey: "GDW",
        header: "gdw",
        size: 50,
        accessorFn: (row) => {
          return row.GDW !== null && row.GDW !== 0 ? (row.GDW < 100 ? row.GDW : Math.round(row.GDW / 100) * 100) : '-';
        }
      },
      {
        accessorKey: "spr500",
        header: "S500",
        size: 50,
        accessorFn: (row) => row.spr500,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value !== null ? `${Math.round(value * 100)}%` : '-';
        }
      },
      {
        accessorKey: "sprGDV",
        header: "sgdv",
        size: 50,
        accessorFn: (row) => row.sprGDV,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return value !== null ? `${Math.round(value * 100)}%` : '-';
        }
      },
      {
        accessorKey: "clientOffer",
        header: "offer",
        size: 50,
        accessorFn: (row) => {
          return row.clientOffer !== null && row.clientOffer !== 0 ? (row.clientOffer < 100 ? row.clientOffer : Math.round(row.clientOffer)) : '-';
        }
      },
      {
        accessorKey: "reco",
        header: "reco",
        size: 50,
        accessorFn: (row) => {
          return row.reco !== null && row.reco !== 0 ? (row.reco < 100 ? row.reco : Math.round(row.reco)) : '-';
        }
      },
      {
        accessorKey: "apr",
        header: "apr",
        size: 50,
        accessorFn: (row) => {
          return row.apr !== null && row.apr !== 0 ? (row.apr < 100 ? row.apr : Math.round(row.apr)) : '-';
        }
      },
      {
        accessorKey: 'user',
        header: 'Allocated To',
        size: 100,
        Cell: ({ row }) => {
          const allocation = row.original.presentAllocation;
          const teamAllocation = row.original.presentTeamAllocations?.[0];

          // Build tooltip content
          const tooltipContent = [];
          if (teamAllocation?.user) {
            tooltipContent.push(`Team: ${teamAllocation.user.firstName} ${teamAllocation.user.lastName}`);
          }
          if (allocation?.user) {
            tooltipContent.push(`Processor: ${allocation.user.firstName} ${allocation.user.lastName}`);
          }

          if (allocation?.user) {
            // Individual processor allocation
            return (
              <Tooltip
                title={tooltipContent.join('\n')}
                placement="bottom"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Person sx={{ color: '#FFA726', fontSize: '1.2rem' }} />
                  <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic' }}>
                    {`${allocation.user.firstName} ${allocation.user.lastName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else if (teamAllocation?.user) {
            // Team allocation
            return (
              <Tooltip
                title={tooltipContent.join('\n')}
                placement="bottom"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Groups sx={{ color: '#42A5F5', fontSize: '1.2rem' }} />
                  <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic' }}>
                    {`Team ${teamAllocation.user.firstName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else {
            // Not allocated
            return (
              <Tooltip title="Not Allocated" placement="bottom">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Person sx={{ color: '#9E9E9E', fontSize: '1.2rem' }} />
                  <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic', color: '#9E9E9E' }}>
                    Unallocated
                  </Typography>
                </Box>
              </Tooltip>
            );
          }
        }
      },
      {
        accessorKey: 'allocator',
        header: 'Allocated By',
        size: 100,
        Cell: ({ row }) => {
          const allocation = row.original.presentAllocation;
          const teamAllocation = row.original.presentTeamAllocations?.[0];

          // Build tooltip content
          const tooltipContent = [];
          if (teamAllocation?.allocator) {
            tooltipContent.push(`Team Allocator: ${teamAllocation.allocator.firstName} ${teamAllocation.allocator.lastName}`);
          }
          if (allocation?.allocator) {
            tooltipContent.push(`Processor Allocator: ${allocation.allocator.firstName} ${allocation.allocator.lastName}`);
          }

          const allocator = allocation?.allocator || teamAllocation?.allocator;

          if (allocator) {
            return (
              <Tooltip
                title={tooltipContent.join('\n')}
                placement="bottom"
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Person sx={{ color: '#FFA726', fontSize: '1.2rem' }} />
                  <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic' }}>
                    {`${allocator.firstName} ${allocator.lastName}`}
                  </Typography>
                </Box>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="Not Allocated" placement="bottom">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Person sx={{ color: '#9E9E9E', fontSize: '1.2rem' }} />
                  <Typography variant="caption" sx={{ fontSize: '0.7rem', fontStyle: 'italic', color: '#9E9E9E' }}>
                    Unallocated
                  </Typography>
                </Box>
              </Tooltip>
            );
          }
        }
      },
      {
        accessorKey: "tags",
        header: "tag",
        size: 50,
        filterVariant: "multi-select",
        filterSelectOptions: tags,
        filterFn: (row, id, filterValue) => {
          if (!filterValue.length) return true;
          return row.tags?.some(tag => filterValue.includes(tag));
        },
        Cell: ({ cell }) => cell.getValue()?.length > 0 ? cell.getValue()[0].tag : '-'
      },
      {
        accessorKey: "deadline",
        header: "deadline",
        size: 70,
        accessorFn: (row) => {
          const date = row.deadline ? new Date(row.deadline) : null;
          return date;
        },
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const date = cell.getValue();
          if (!date) return '-';
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        }
      },
      {
        accessorKey: "auctionType",
        header: "auctionType",
        size: 120,
        accessorFn: (row) => row.auctionType || '-'
      },
    ],
    [tags]
  );

  const columnVisibility = {
    platform: false,
    auctionType: false,
    aby: false,
    regx: false,
    bids: false,
    tld_count: false,
    ESW: false,
    GDW: false,
    spr500: false,
    sprGDV: false,
    tags: false,
    reco: false,
    apr: false,
    host: false,
    deadline: false
  }

  const domainTable = useMaterialReactTable({
    columns: columns,
    data: data,
    initialState: {
      density: 'compact',
      pagination: { pageSize: 50, pageIndex: 0 },
      sorting: [],
      columnVisibility: columnVisibility,
    },
    enableColumnFilterModes: true,
    enableRowSelection: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableSorting: true,
    enableMultiSort: true,
    enableFilters: false,
    enableGlobalFilter: true,
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    maxMultiSortColCount: 3,
    rowCount: totalRowCount,
    onPaginationChange: setPagination,

    muiTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    muiTablePaperProps: {
      sx: {
        boxShadow: 'none',
        border: '1px solid #e0e0e0',
      },
    },
    muiTableContainerProps: {
      sx: {
        maxHeight: '600px',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderBottom: '1px solid #e0e0e0',
        padding: '4px',
      },
    },
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #e0e0e0',
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: '#FFC0CB',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
        '& .MuiTableSortLabel-icon': {
          display: 'none',
        },
        '& .Mui-active .MuiTableSortLabel-icon': {
          display: 'none',
        }
      },
    },
    muiTableBodyCellProps: {
      sx: {
        fontSize: '0.7rem',
        padding: '0px',
        margin: '0px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontStyle: 'italic',
      },
    },
    muiTableBodyRowProps: ({ row }) => ({
      sx: {
        backgroundColor: row.index % 2 === 0 ? '#ffffff' : '#f5f5f5',
        '&:hover': {
          backgroundColor: '#e3f2fd !important',
        },
      },
    }),

    renderTopToolbarCustomActions: ({ table }) => {
      const selectedRows = table.getSelectedRowModel().rows;
      const activeFiltersCount = columnFilters.length;
      const activeSortsCount = sorting.length;

      return (
        <Stack direction='row' spacing={1} alignItems='center' width="100%">
        <CustomToggleButton
          value="showNew24h"
          selected={showNew24h}
          onChange={() => setShowNew24h(!showNew24h)}
          label="new"
          tooltipText="New-24h"
        />
          <Box flexGrow={1} />
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              p: '4px',
              gap: '8px'
            }}
          >
            <TextField
              placeholder="Search domains..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleSearch();
              }}
              size="small"
              sx={{
                width: '160px',
                '& .MuiOutlinedInput-root': {
                  height: '28px',
                  fontSize: '0.875rem',
                  backgroundColor: '#ffffff',
                  borderRadius: '12px',
                  '& fieldset': {
                    borderColor: '#e0e0e0',
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#8797A8',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#8797A8',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{ 
                        fontSize: '1rem', 
                        color: '#8797A8',
                        cursor: 'pointer',
                        '&:hover': {
                          color: '#6b7a8a',
                        },
                      }}
                      onClick={handleSearch}
                    />
                  </InputAdornment>
                ),
              }}
            />

            <Box sx={{ display: 'flex', gap: '4px' }}>
              <Tooltip title={`Sort (${activeSortsCount} active)`} arrow placement="top">
                <Badge 
                  badgeContent={activeSortsCount} 
                  color="primary"
                  sx={{
                    '& .MuiBadge-badge': {
                      fontSize: '0.5rem',
                      height: '12px',
                      minWidth: '12px',
                      padding: '0 3px'
                    }
                  }}
                >
                  <IconButton
                    onClick={() => setOpenSortDialog(true)}
                    size="small"
                    sx={commonButtonStyles}
                  >
                    <SortIcon sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </Badge>
              </Tooltip>

              <Tooltip title={`Filter (${activeFiltersCount} active)`} arrow placement="top">
                <Badge 
                  badgeContent={activeFiltersCount} 
                  color="primary"
                  sx={{
                    '& .MuiBadge-badge': {
                      fontSize: '0.5rem',
                      height: '12px',
                      minWidth: '12px',
                      padding: '0 3px'
                    }
                  }}
                >
                  <IconButton
                    onClick={() => setOpenFilterDialog(true)}
                    size="small"
                    sx={commonButtonStyles}
                  >
                    <FilterListIcon sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </Badge>
              </Tooltip>

              <Tooltip title="Export Selected" arrow placement="top">
                <span>
                  <IconButton
                    onClick={() => handleExportData(selectedRows)}
                    disabled={selectedRows.length === 0}
                    size="small"
                    sx={commonButtonStyles}
                  >
                    <FileDownloadOutlined sx={{ fontSize: '1rem' }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Stack>
        </Stack>
      );
    },

    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [50, 150, 250, 500, 1000],
      shape: 'rounded',
      variant: 'outlined',
    },

    state: {
      pagination,
      sorting,
      columnFilters,
      isLoading: loading,
      globalFilter: globalSearch
    },
  });

  const ltdTabTypes = ['ALL', 'ALD', 'DD', 'GD', 'NC', 'NS', 'PD', 'SD', 'SV'];

  const handleExportData = useCallback((selectedRows) => {
    try {
      const exportColumns = [
        'domain', 'type', 'lander', 'host', 'wby', 'aby', 'reg', 'regx',
        'tld_count', 'tld_count_dev', 'sg', 'cpc', 'bids', 'currentPrice',
        'EST', 'GDV', 'ESW', 'GDW', 'spr500', 'sprGDV', 'clientOffer', 'reco'
      ];

      const header = exportColumns.join(',') + '\n';
      const csvContent = selectedRows.map(row => {
        return exportColumns.map(col => {
          const value = row.original[col];
          if (value === null || value === undefined) return '';
          if (typeof value === 'string' && value.includes(',')) return `"${value}"`;
          return value;
        }).join(',');
      }).join('\n');

      const csv = header + csvContent;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `ltd_domains_archive_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setUpsuc(true);
    } catch (error) {
      console.error('Export error:', error);
      setUpfail(true);
    }
  }, []);

  return (<ThemeProvider theme={theme}>
    <Stack direction='column' spacing={5}>
      <Stack direction='row' spacing={2}>
        <Typography
          variant="h6"
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          My Domains
        </Typography>
        <Typography
          variant="h6"
          color='gray'
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
          }}
        >
          LTD Deleted
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        {(isAdmin() || canAddLtd()) && <>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Edit />}
            onClick={() => { setOpenDialog1(true); }}
            sx={{
              height: 40,
              borderRadius: 100,
              textTransform: 'none'
            }}
          >
            Edit Domains
          </Button>
          {/* <UploadCSVDialog title='Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} /> */}
          <UpdateDomain title='Update Domains' open={openDialog1} handleClose={handleClose1} setState={setEditDomains} /> 
          
          </>}

      </Stack>
      <Snackbar open={upsuc} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Domains Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Domain Not Uploaded!
        </Alert>
      </Snackbar>
      <Snackbar open={upsuc1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpsuc1(false); }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Allotted!
        </Alert>
      </Snackbar>
      <Snackbar open={upfail1} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => { setUpfail1(false); }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Not Allotted!
        </Alert>
      </Snackbar>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MaterialReactTable table={domainTable} />
        </LocalizationProvider>
      </Box>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AllocationDialog
            open={openAllocate}
            onClose={handleCloseAllocate}
            selectedIds={idds}
            onSuccess={() => {
              setUpsuc1(true);
              fetchData();
            }}
            onError={() => setUpfail1(true)}
            onComplete={() => {
              setIdds([]);
            }}
          />
          <MultiFilterDialog
            open={openFilterDialog}
            onClose={() => setOpenFilterDialog(false)}
            columns={columns}
            initialFilters={columnFilters}
            onApplyFilter={handleFilterApply}
          />

          <MultiSortDialog
            open={openSortDialog}
            onClose={() => setOpenSortDialog(false)}
            columns={columns}
            initialSorting={sorting}
            onApplySort={handleSortApply}
          />

          <OverwriteDialog
            open={openExistingDomainsDialog}
            onClose={handleCloseExistingDomainsDialog}
            existingDomains={existingDomains}
            selectedDomains={selectedExistingDomains}
            onSelectAll={handleSelectAll}
            onDomainSelect={handleExistingDomainSelect}
            onOverwrite={() => {
              const domainsToOverwrite = domains.filter(domain => 
                selectedExistingDomains.some(selected => 
                  selected.toLowerCase() === domain.domain.toLowerCase()
                )
              );
              
              if (domainsToOverwrite.length > 0) {
                handleUploadDomains(domainsToOverwrite, true);
              }
            }}
          />
        </LocalizationProvider>
      </Box>
    </Stack>
  </ThemeProvider>
  )
}